import Vue from 'vue'
import VueRouter from 'vue-router'
import SimulationPage from './pages/SimulationPage.vue'
import ComparisonPage from './pages/ComparisonPage.vue'
import VisualizationPage from './pages/VisualizationPage.vue'

Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        {
            path: '/', 
            component: VisualizationPage
        },
        {
            path: '/simular/db', 
            component: SimulationPage
        },
        {
            path: '/comparar/db', 
            component: ComparisonPage
        }
    ]
})