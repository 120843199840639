<template>
<b-card no-body >
    <!-- b-card-header -->
<b-card-header header-tag="header" class="p-2" role="tab">
<b-container class="bv-example-row">
        <b-row :class="score_changed">
                <b-col cols="9" class="text-left">
                <b-button block href="#" v-b-toggle.accordion-9 variant="info" class="tarjeta">Cumplimiento de contratos</b-button>
                </b-col>
                <b-col>
                <b-form-input readonly v-bind:value="ec_score" class="text-center"></b-form-input>
                </b-col>
        </b-row>
</b-container>
</b-card-header>
<!-- b-card-header -->
<b-collapse id="accordion-9"  accordion="my-accordion" role="tabpanel">
    <b-card-body>
    <b-card-text>

        <b-container class="bv-example-row"> 
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="ec_time_changed" cols="9" class="border-left text-left " >Tiempo (días)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="ec_time_changed = 'changed'" v-model.lazy="ec_time" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="ec_cost_changed" cols="9" class="border-left text-left " >Costo (% de cantidad demandada)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="ec_cost_changed = 'changed'" v-model.lazy="ec_cost" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col  v-b-tooltip.hover.top title="Valor entre 0 y 18; Escriba NP si no se practica" :class="ec_qjpi_changed" cols="9" class="border-left text-left " >Índice de calidad de los procesos judiciales (0-18)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyNumberBetweenAndNP($event, 18)" number trim @input="ec_qjpi_changed = 'changed'"  v-model.lazy="ec_qjpi" class="text-center"></b-form-input>
                </b-col>
            </b-row>
        </b-container>

    </b-card-text>
    </b-card-body>
</b-collapse>
</b-card>
</template>

<script>
import { mapState } from 'vuex'
import filters from '../../libs/inputFilters'

export default {
    data() {
        return {
            ec_qjpi_changed: '',
            ec_time_changed: '',
            ec_cost_changed: ''
        }
    },
    methods: {
        ...filters
    },
    computed: {
        score_changed() {
            if (this.ec_qjpi_changed == 'changed' ||
                this.ec_time_changed == 'changed' ||
                this.ec_cost_changed == 'changed')
                return 'changed'
            else return ''
        },
        ec_qjpi: {
            get() {
                if (this.$store.state.ec_qjpi === "No Practice") 
                    return "NP"
                else return this.$store.state.ec_qjpi
            },
            set(value) {
                if (value == "NP") value = "No Practice"
                this.$store.commit('ec_qjpi', value)
            }
        },
        ec_time: {
            get() {
                return this.$store.state.ec_time
            },
            set(value) {
                this.$store.commit('ec_time', value)
            }
        },
        ec_cost: {
            get() {
                return this.$store.state.ec_cost
            },
            set(value) {
                this.$store.commit('ec_cost', value)
            }
        },
        ec_score() {
            if (isNaN(this.$store.getters.ec_score)) return ""
            return this.$store.getters.ec_score.toFixed(1)
        },
    }
}
</script>

<style scoped>
.tarjeta{
color: #5D5D5D;
font-weight: bold;
background-color: white;
text-align: left;
border-color: #81B3E8;
}
.variable{
font-weight: bold;
color: #5D5D5D;
}
@media (min-width: 1900px) and (max-width: 1921px) 
{
.tarjeta
{
    height: 70px;
}
}
.changed {
    background: lightcyan;
}
</style>