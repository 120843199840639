export default {
	onlyNumber($event) {
           let key = $event.key;
           let possible_inputs = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "Backspace",
                                    "ArrowLeft", "ArrowRight"]
           if (!possible_inputs.includes(key)) {
                $event.preventDefault();
           }
        },
        onlyDecimal($event) {
           let key = $event.key;
           let possible_inputs = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "Backspace",
                                    "ArrowLeft", "ArrowRight", "."]
           if (!possible_inputs.includes(key) || (key === "." && $event.target.value.includes("."))) {
                $event.preventDefault();
           }
        },
        onlyNumberBetween($event, max) {
            let key = $event.key;
            let possible_inputs = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "Backspace",
                                    "ArrowLeft", "ArrowRight", "."]
            if (!possible_inputs.includes(key) ||
                (!isNaN(key) && $event.target.value.concat(key) > max) ||
                (key === "." && $event.target.value.includes("."))) {
                $event.preventDefault();
            }
        },
        onlyNumberBetweenAndNP($event, max) {
            let key = $event.key;
            let possible_inputs = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "Backspace",
                                    "ArrowLeft", "ArrowRight", ".", "N", "P"]
            if (!possible_inputs.includes(key) || 
                ((!isNaN(key) || key === ".") && ($event.target.value == "N" || $event.target.value == "NP")) ||
                (!isNaN(key) && $event.target.value.concat(key) > max) ||
                (key === "." && $event.target.value.includes(".")) ||
                (key === "N" && $event.target.value !== "") ||
                (key === "P" && $event.target.value !== "N")) {
                $event.preventDefault();
            }
        },
}