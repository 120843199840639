<template>
<div>
    <div role="tablist" class="mb-2">
     <b-card no-body>
      <b-card-header header-tag="header" class="p-1" role="tab">
            <b-container fluid class="bv-example-row">
                <b-row>
                    <b-col cols="8">
                    <b-button block href="#" v-b-toggle="extraeRankScore.topico" variant="info" class="tarjeta text-left" > {{extraeRankScore.topico}} </b-button>    
                    </b-col>
                    <b-col ><strong class="text-success">{{extraeRankScore.posicion}}</strong></b-col>
                    <b-col class="border-right"><strong class="text-info">{{parseFloat(extraeRankScore.puntaje).toFixed(2)}}</strong></b-col>
                    <b-col class="text-info" :id="extraeRankScore.topico">{{parseFloat(extraeRankScore.mayorRegional).toFixed(2)}}</b-col>
                    <b-col class="text-info" :id="extraeRankScore.contador">{{parseFloat(extraeRankScore.mayorGlobal).toFixed(2)}}</b-col>
                    <b-popover :target="extraeRankScore.topico" triggers="hover" placement="top">
                      <template><h6 class="text-secondary text-center">Mejor región</h6></template>
                      <h6 class="text-center text-info"><strong >{{ extraeRankScore.paisRegional }}</strong></h6>
                    </b-popover>
                    <b-popover :target="extraeRankScore.contador" triggers="hover" placement="top">
                      <template><h6 class="text-secondary text-center">Mejor global</h6></template>
                      <h6 class="text-center text-info"><strong>{{ extraeRankScore.paisGlobal }}</strong></h6>
                    </b-popover> 
                </b-row>
            </b-container>
      </b-card-header>
     <b-collapse :id="extraeRankScore.topico" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <b-container fluid class="bv-example-row">
                <b-row v-for="(item, index) in extraeRankScore.indicadores" :key="index">
                    <b-col cols="8" class="border-bottom text-left"><h6>{{item.indicador}}</h6></b-col>
                    <b-col class="border-left border-bottom"><h6>{{parseFloat(item.numero).toFixed(2)}}</h6></b-col>
                </b-row>
                <b-row class="mb-2">
                        <b-col>
                          <ChartLine v-if="historiacargada" class="line-chart-height" :chartdata="datacollectionLinePSC" :options="chartOptionsLinePSC"></chartLine>
                        </b-col>
                        <b-col>
                          <ChartLine v-if="historiacargada" class="line-chart-height" :chartdata="datacollectionLinePTJ" :options="chartOptionsLinePTJ"></chartLine>
                        </b-col>
                </b-row>  
            </b-container>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
</div>

</div>
</template>

<script>
import ChartLine from '../charts/BaseNRLine'

export default {
    name:'TopicCard',
    components:
    {
       ChartLine
    },
    data()
    {
      return{
        datacollectionLinePSC: null,
        datacollectionLinePTJ: null,
        historiacargada: false,
        chartOptionsLinePSC: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [{
                  ticks: {                                    
                          reverse:true
                          }
                    }]
                }
              },
        chartOptionsLinePTJ: {
              responsive: true,
              maintainAspectRatio: false
                    },
      }
    },
    props:[
            "extraeRankScore",
            "APIhistoria"
          ],
    created() {
        let datos = []
        let fecha_topicoPSC = this.APIhistoria.filter(item => item.topic == this.extraeRankScore.topico && item.indicator == "Rank").map(item => item.year).sort()
        let APIhistoriaPSC = this.APIhistoria.filter(item => item.topic == this.extraeRankScore.topico && item.indicator == "Rank");
        for(let b = 0 ; b < fecha_topicoPSC.length ; b++) {
          for(let c = 0 ; c < APIhistoriaPSC.length ; c++) {
            if(APIhistoriaPSC[c].year == fecha_topicoPSC[b]) {
              datos.push(APIhistoriaPSC[c].value)
            }
          }
        }

        this.datacollectionLinePSC = {
          labels: fecha_topicoPSC,
          datasets: [
            {
              label: "Posición",
              backgroundColor: "transparent",
              borderColor: "rgba(1, 116, 188, 0.6)",
              pointBackgroundColor: "rgba(1, 116, 188, 1)",
              borderWidth: 5,
              pointRadius: 7,
              data: datos
            }
          ]
        }

        let fecha_topicoPTJ = this.APIhistoria.filter(item => item.topic == this.extraeRankScore.topico && item.indicator == "Score").map(item => item.year).sort()
        let APIhistoriaPTJ = this.APIhistoria.filter(item => item.topic == this.extraeRankScore.topico && item.indicator == "Score");
        datos = []
        for(let b = 0 ; b < fecha_topicoPTJ.length ; b++) {
          for(let d = 0 ; d < APIhistoriaPTJ.length ; d++) {
            if(APIhistoriaPTJ[d].year == fecha_topicoPTJ[b]) {
              datos.push(parseFloat(APIhistoriaPTJ[d].value).toFixed(2))
            }
          }
        }

        this.datacollectionLinePTJ = {
          labels: fecha_topicoPTJ,
          datasets: [
            {
              label: "Puntaje",
              backgroundColor: "transparent",
              borderColor: "rgba(63, 31, 149, 0.6)",
              pointBackgroundColor: "rgba(63, 31, 149, 1)",
              borderWidth: 5,
              pointRadius: 7,
              data: datos
            }
          ]
        }

        this.historiacargada = true
    },
}
</script>

<style scoped>
.tarjeta{
    color: #5D5D5D;
    font-weight: bold;
    background-color: white; 
    text-align: left;
     border-color: #81B3E8;
}
.line-chart-height {
  height: 300px;
}
</style>