<template>
    <b-row>
        <b-col class="" v-for="(item,index) in cincoArribaAbajo" :key="index">
                <div v-if="item.general.economy === almacena_pais" >
                    <b-button class="btn-md" variant="outline-warning" :id="item.general.code">
                        <h6 class="centered font-weight-bold">{{item.general.code}}</h6>
                    </b-button> 
                </div>
                <div v-else>
                    <b-button class="btn-md" variant="outline-success" :id="item.general.code">
                        <h6 class="centered font-weight-bold">{{item.general.code}}</h6>
                    </b-button> 
                </div>
                    <b-popover v-if="eleccion" :target="item.general.code" triggers="hover" placement="righttop" >
                        <template v-slot:title>{{item.general.economy}}</template>
                        Posición regional: {{item.rankRegion}} <br>
                        Puntuación: {{parseFloat(item.general.score).toFixed(2)}}
                    </b-popover> 
                    <b-popover v-else :target="item.general.code" triggers="hover" placement="righttop" >
                        <template v-slot:title>{{item.general.economy}}</template>
                        Posición mundial: {{item.general.rank}} <br>
                        Puntuación: {{parseFloat(item.general.score).toFixed(2)}}
                    </b-popover> 
        </b-col>
    </b-row>
</template>

<script>
export default {
    name:'FiveTop',
    props: {
            cincoArribaAbajo: Array,
            almacena_pais: String,
            eleccion: Boolean
            },
        created(){
           this.asignar()
          },
          methods:{
            asignar(){
               // console.log("fivetopp")
            }
          }

}
</script>

<style scoped>
.btn-md{
  width: 57px;
    height: 57px;
    border-radius: 50%;
    font-size: 24px;
    border-width: 2px;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>