let setStoreData = {
    set_sab_store_data(store, request_data) {
        let data = request_data.filter(item => item.topic == "Apertura de un negocio")
        store.commit("sab_proc_men", data.filter(item => item.indicator == "Procedimientos - Hombres (número)")[0].value)
        store.commit("sab_time_men", data.filter(item => item.indicator == "Tiempo - Hombres (días)")[0].value)
        store.commit("sab_cost_men", data.filter(item => item.indicator == "Costo - Hombres (% de ingreso per cápita)")[0].value)
        store.commit("sab_proc_women", data.filter(item => item.indicator == "Procedimientos - Mujeres (número)")[0].value)
        store.commit("sab_time_women", data.filter(item => item.indicator == "Tiempo - Mujeres (días)")[0].value)
        store.commit("sab_cost_women", data.filter(item => item.indicator == "Costo - Mujeres (% de ingreso per cápita)")[0].value)
        store.commit("sab_pim_capital", data.filter(item => item.indicator == "Requisito de capital mínimo pagado (% de ingreso per cápita)")[0].value)
    },

    set_dwcp_store_data(store, request_data) {
        let data = request_data.filter(item => item.topic == "Manejo de permisos de construcción")
        store.commit("dwcp_proc", data.filter(item => item.indicator == "Procedimientos (número)")[0].value)
        store.commit("dwcp_time", data.filter(item => item.indicator == "Tiempo (días)")[0].value)
        store.commit("dwcp_cost", data.filter(item => item.indicator == "Costo (% del valor de la construcción)")[0].value)
        store.commit("dwcp_bqci", data.filter(item => item.indicator == "Índice de control de calidad de la construcción (0-15)")[0].value)
    },

    set_ge_store_data(store, request_data) {
        let data = request_data.filter(item => item.topic == "Obtención de Electricidad")
        store.commit("ge_proc", data.filter(item => item.indicator == "Procedimientos (número)")[0].value)
        store.commit("ge_time", data.filter(item => item.indicator == "Tiempo (días)")[0].value)
        store.commit("ge_cost", data.filter(item => item.indicator == "Costo (% de ingreso per cápita)")[0].value)
        store.commit("ge_rstt", data.filter(item => item.indicator == "Índice de fiabilidad del suministro y transparencia de las tarifas (0-8)")[0].value)
    },

    set_regp_store_data(store, request_data) {
        let data = request_data.filter(item => item.topic == "Registro de propiedades")
        store.commit("rp_proc", data.filter(item => item.indicator == "Procedimientos (número)")[0].value)
        store.commit("rp_time", data.filter(item => item.indicator == "Tiempo (días)")[0].value)
        store.commit("rp_cost", data.filter(item => item.indicator == "Costo (% del valor de la propiedad)")[0].value)
        store.commit("rp_qlai", data.filter(item => item.indicator == "Índice de calidad del sistema de administración de bienes inmuebles (0-30)")[0].value)
    },

    set_gc_store_data(store, request_data) {
        let data = request_data.filter(item => item.topic == "Obtención de crédito")
        store.commit("gc_cii", data.filter(item => item.indicator == "Índice de alcance de la información crediticia (0-8)")[0].value)
        store.commit("gc_lri", data.filter(item => item.indicator == "Índice de fortaleza de los derechos legales (0-12)")[0].value)
    },

    set_pmi_store_data(store, request_data) {
        let data = request_data.filter(item => item.topic == "Protección de los inversionistas minoritarios")
        store.commit("pmi_di", data.filter(item => item.indicator == "Índice de grado de transparencia (0-10)")[0].value)
        store.commit("pmi_dli", data.filter(item => item.indicator == "Indice de responsabilidad del los directores (0-10)")[0].value)
        store.commit("pmi_ssi", data.filter(item => item.indicator == "Índice de facilidad de los juicios de los accionistas (0-10)")[0].value)
        store.commit("pmi_sri", data.filter(item => item.indicator == "Índice de derechos de los accionistas (0-6)")[0].value)
        store.commit("pmi_oci", data.filter(item => item.indicator == "Índice de fortaleza de la estructura de la gobernanza corporativa (0-7)")[0].value)
        store.commit("pmi_cti", data.filter(item => item.indicator == "Índice de transparencia corporativa (0-7)")[0].value)
    },

    set_pt_store_data(store, request_data) {
        let data = request_data.filter(item => item.topic == "Pago de impuestos")
        store.commit("pt_payments", data.filter(item => item.indicator == "Pagos (número por año)")[0].value)
        store.commit("pt_time", data.filter(item => item.indicator == "Tiempo (horas por año)")[0].value)
        store.commit("pt_ttr", data.filter(item => item.indicator == "Tasa de impuestos y contribuciones total (% de ganancia)")[0].value)
        store.commit("pt_tcvr", data.filter(item => item.indicator == "Time to comply with VAT refund (hours) (DB17-20 methodology)")[0].value)
        store.commit("pt_tovr", data.filter(item => item.indicator == "Time to obtain VAT refund (weeks) (DB17-20 methodology)")[0].value)
        store.commit("pt_t_comply_cita", data.filter(item => item.indicator == "Time to comply with a corporate income tax correction (hours) (DB17-20 methodology)")[0].value)
        store.commit("pt_t_complete_cita", data.filter(item => item.indicator == "Time to complete a corporate income tax correction (weeks) (DB17-20 methodology)")[0].value)
        let pfi_arr = data.filter(item => item.indicator == "Puntaje-Índice posterior a la declaración de impuestos (0-100)")
        store.commit("pt_pfi", pfi_arr.length > 0 ? pfi_arr[0].value : null)
    },

    set_tab_store_data(store, request_data) {
        let data = request_data.filter(item => item.topic == "Comercio transfronterizo")
        store.commit("tab_ttebc", data.filter(item => item.indicator == "Tiempo para exportar: Cumplimiento fronterizo (horas)")[0].value)
        store.commit("tab_ttedc", data.filter(item => item.indicator == "Tiempo para exportar: Cumplimiento documental (horas)")[0].value)
        store.commit("tab_ctebc", data.filter(item => item.indicator == "Costo para exportar: Cumplimiento fronterizo (USD)")[0].value)
        store.commit("tab_ctedc", data.filter(item => item.indicator == "Costo para exportar: Cumplimiento documental (USD)")[0].value)
        store.commit("tab_ttibc", data.filter(item => item.indicator == "Tiempo para importar: Cumplimiento fronterizo (horas)")[0].value)
        store.commit("tab_ttidc", data.filter(item => item.indicator == "Tiempo para importar: Cumplimiento documental (horas)")[0].value)
        store.commit("tab_ctibc", data.filter(item => item.indicator == "Costo para importar: Cumplimiento fronterizo (USD)")[0].value)
        store.commit("tab_ctidc", data.filter(item => item.indicator == "Costo para importar: Cumplimiento documental (USD)")[0].value)
    },

    set_ec_store_data(store, request_data) {
        let data = request_data.filter(item => item.topic == "Cumplimiento de contratos")
        store.commit("ec_qjpi", data.filter(item => item.indicator == "Índice de calidad de los procesos judiciales (0-18)")[0].value)
        store.commit("ec_time", data.filter(item => item.indicator == "Tiempo (días)")[0].value)
        store.commit("ec_cost", data.filter(item => item.indicator == "Costo (% de cantidad demandada)")[0].value)
    },

    set_ri_store_data(store, request_data) {
        let data = request_data.filter(item => item.topic == "Resolución de la insolvencia")
        store.commit("ri_rr", data.filter(item => item.indicator == "Tasa de recuperación (centavos de dólar)")[0].value)
        store.commit("ri_sifi", data.filter(item => item.indicator == "Índice de la fortaleza del marco regulatorio de la insolvencia (0-16)")[0].value)
    }
}

export default setStoreData