<template>
<b-card no-body >
    <!-- b-card-header -->
<b-card-header header-tag="header" class="p-2" role="tab">
<b-container class="bv-example-row">
        <b-row :class="score_changed">
                <b-col cols="9" class="text-left">
                <b-button block href="#" v-b-toggle.accordion-4 variant="info" class="tarjeta">Registro de propiedades</b-button>
                </b-col>
                <b-col>
                <b-form-input readonly v-bind:value="rp_score" class="text-center"></b-form-input>
                </b-col>
        </b-row>
</b-container>
</b-card-header>
<!-- b-card-header -->
<b-collapse id="accordion-4"  accordion="my-accordion" role="tabpanel">
    <b-card-body>
    <b-card-text>

        <b-container class="bv-example-row">
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="rp_proc_changed" cols="9" class="border-left text-left " >Procedimientos (número)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="rp_proc_changed = 'changed'" v-model.lazy="rp_proc" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="rp_time_changed" cols="9" class="border-left text-left " >Tiempo (días)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="rp_time_changed = 'changed'" v-model.lazy="rp_time" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="rp_cost_changed" cols="9" class="border-left text-left " >Costo (% del valor de la propiedad)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="rp_cost_changed = 'changed'" v-model.lazy="rp_cost" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Valor entre 0 y 30; Escriba NP si no se practica" :class="rp_qlai_changed" cols="9" class="border-left text-left ">Índice de calidad del sistema de administración de bienes inmuebles (0-30)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyNumberBetweenAndNP($event, 30)" number trim @input="rp_qlai_changed = 'changed'" v-model.lazy="rp_qlai" class="text-center"></b-form-input>
                </b-col>
            </b-row>
        </b-container>

    </b-card-text>
    </b-card-body>
</b-collapse>
</b-card>
</template>

<script>
import { mapState } from 'vuex'
import filters from '../../libs/inputFilters'

export default {
    data() {
        return {
            rp_proc_changed: '',
            rp_time_changed: '',
            rp_cost_changed: '',
            rp_qlai_changed: ''
        }
    },
    methods: {
        ...filters
    },
    computed: {
        score_changed() {
            if (this.rp_proc_changed == 'changed' ||
                this.rp_time_changed == 'changed' ||
                this.rp_cost_changed == 'changed' ||
                this.rp_qlai_changed == 'changed')
                return 'changed'
            else return ''
        },
        rp_proc: {
            get() {
                return this.$store.state.rp_proc
            },
            set(value) {
                this.$store.commit('rp_proc', value)
            }
        },
        rp_time: {
            get() {
                return this.$store.state.rp_time
            },
            set(value) {
                this.$store.commit('rp_time', value)
            }},
        rp_cost: {
            get() {
                return this.$store.state.rp_cost
            },
            set(value) {
                this.$store.commit('rp_cost', value)
            }
        },
        rp_qlai: {
            get() {
                if (this.$store.state.rp_qlai === "No Practice") 
                    return "NP"
                else return this.$store.state.rp_qlai
            },
            set(value) {
                if (value == "NP") value = "No Practice"
                this.$store.commit('rp_qlai', value)
            }
        },
        rp_score() {
            if (isNaN(this.$store.getters.rp_score)) return ""
            return this.$store.getters.rp_score.toFixed(1)
        },
    }
}
</script>

<style scoped>
.tarjeta{
color: #5D5D5D;
font-weight: bold;
background-color: white;
text-align: left;
border-color: #81B3E8;
}
.variable{
font-weight: bold;
color: #5D5D5D;
}
@media (min-width: 1900px) and (max-width: 1921px) 
{
.tarjeta
{
    height: 70px;
}
}
.changed {
    background: lightcyan;
}
</style>