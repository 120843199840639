<template>
<b-card no-body >
    <!-- b-card-header -->
<b-card-header header-tag="header" class="p-2" role="tab">
<b-container class="bv-example-row">
        <b-row :class="score_changed">
                <b-col cols="9" class="text-left">
                <b-button block href="#" v-b-toggle.accordion-6 variant="info" class="tarjeta">Protección de los inversionistas minoritarios</b-button>
                </b-col>
                <b-col>
                <b-form-input readonly v-bind:value="pmi_score" class="text-center"></b-form-input>
                </b-col>
        </b-row>
</b-container>
</b-card-header>
<!-- b-card-header -->
<b-collapse id="accordion-6"  accordion="my-accordion" role="tabpanel">
    <b-card-body>
    <b-card-text>

        <b-container class="bv-example-row">
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Valor entre 0 y 10" :class="pmi_di_changed" cols="9" class="border-left text-left " >Índice de grado de transparencia (0-10)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyNumberBetween($event, 10)" number trim @input="pmi_di_changed = 'changed'" v-model.lazy="pmi_di" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Valor entre 0 y 10" :class="pmi_dli_changed" cols="9" class="border-left text-left " >Indice de responsabilidad del los directores (0-10)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyNumberBetween($event, 10)" number trim @input="pmi_dli_changed = 'changed'" v-model.lazy="pmi_dli" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Valor entre 0 y 10" :class="pmi_ssi_changed" cols="9" class="border-left text-left " >Índice de facilidad de los juicios de los accionistas (0-10)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyNumberBetween($event, 10)" number trim @input="pmi_ssi_changed = 'changed'" v-model.lazy="pmi_ssi" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Valor entre 0 y 6" :class="pmi_sri_changed" cols="9" class="border-left text-left ">Índice de derechos de los accionistas (0-6)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyNumberBetween($event, 6)" number trim @input="pmi_sri_changed = 'changed'" v-model.lazy="pmi_sri" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Valor entre 0 y 7" :class="pmi_oci_changed" cols="9" class="border-left text-left " >Índice de fortaleza de la estructura de la gobernanza corporativa (0-7)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyNumberBetween($event, 7)" number trim @input="pmi_oci_changed = 'changed'" v-model.lazy="pmi_oci" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Valor entre 0 y 7" :class="pmi_cti_changed" cols="9" class="border-left text-left ">Índice de transparencia corporativa (0-7)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyNumberBetween($event, 7)" number trim @input="pmi_cti_changed = 'changed'" v-model.lazy="pmi_cti" class="text-center"></b-form-input>
                </b-col>
            </b-row>
        </b-container>

    </b-card-text>
    </b-card-body>
</b-collapse>
</b-card>
</template>

<script>
import { mapState } from 'vuex'
import filters from '../../libs/inputFilters'

export default {
    data() {
        return {
            pmi_di_changed: '',
            pmi_dli_changed: '',
            pmi_ssi_changed: '',
            pmi_sri_changed: '',
            pmi_oci_changed: '',
            pmi_cti_changed: ''
        }
    },
    methods: {
        ...filters
    },
    computed: {
        score_changed() {
            if (this.pmi_di_changed == 'changed' ||
                this.pmi_dli_changed == 'changed' ||
                this.pmi_ssi_changed == 'changed' ||
                this.pmi_sri_changed == 'changed' ||
                this.pmi_oci_changed == 'changed' ||
                this.pmi_cti_changed == 'changed')
                return 'changed'
            else return ''
        },
        pmi_di: {
            get() {
                return this.$store.state.pmi_di
            },
            set(value) {
                this.$store.commit('pmi_di', value)
            }
        },
        pmi_dli: {
            get() {
                return this.$store.state.pmi_dli
            },
            set(value) {
                this.$store.commit('pmi_dli', value)
            }
        },
        pmi_ssi: {
            get() {
                return this.$store.state.pmi_ssi
            },
            set(value) {
                this.$store.commit('pmi_ssi', value)
            }
        },
        pmi_sri: {
            get() {
                return this.$store.state.pmi_sri
            },
            set(value) {
                this.$store.commit('pmi_sri', value)
            }},
        pmi_oci: {
            get() {
                return this.$store.state.pmi_oci
            },
            set(value) {
                this.$store.commit('pmi_oci', value)
            }
        },
        pmi_cti: {
            get() {
                return this.$store.state.pmi_cti
            },
            set(value) {
                this.$store.commit('pmi_cti', value)
            }},
        pmi_score() {
            if (isNaN(this.$store.getters.pmi_score)) return ""
            return this.$store.getters.pmi_score.toFixed(1)
        },
    }
}
</script>

<style scoped>
.tarjeta{
color: #5D5D5D;
font-weight: bold;
background-color: white;
text-align: left;
border-color: #81B3E8;
}
.variable{
font-weight: bold;
color: #5D5D5D;
}
@media (min-width: 1900px) and (max-width: 1921px) 
{
.tarjeta
{
    height: 70px;
}
}
.changed {
    background: lightcyan;
}
</style>