<template>
    <div class="comparacion">
        <div v-if="countries_request">
            <b-container  fluid class="bv-example-row " >
              <b-row>
                  <b-col >
                    <div class="comparaPais">
                        <b-container class="bv-example-row mt-3">
                            <b-row >
                                <b-col ><h6>
                                <b-form-select class="selector " v-model="main_country_selected" :options="country_options"></b-form-select>
                                </h6>
                                </b-col>
                                <b-col ><h6>
                                <b-form-select class="selector " v-model="main_year_selected" :options="main_year_options"></b-form-select>
                                </h6>
                                </b-col>
                                <b-col><h6>Comparar con:</h6>
                                </b-col>
                                <b-col><h6>
                                <b-form-select class="selector " v-model="secondary_country_selected" :options="country_options"></b-form-select>
                                </h6>
                                </b-col>
                                <b-col><h6>
                                <b-form-select class="selector " v-model="secondary_year_selected" :options="secondary_year_options"></b-form-select>
                                </h6>
                                </b-col>
                            </b-row>
                            <div v-if="first_selection_data && second_selection_data">
                                <b-row>
                                    <b-col class="border-right"><h6 class="mt-4"><b-icon scale="2" icon="bookmark-fill" class="mr-2" style="color: #46a9e8;"></b-icon>Posición en {{selected_years[0]}}: {{main_country_rank_and_pos.rank}}</h6></b-col>
                                    <b-col class="border-left"><h6 class="mt-4 "><b-icon scale="2" icon="bookmark-fill" class="mr-2" style="color: #AB85C3;"></b-icon>Posición en {{selected_years[1]}}: {{second_country_rank_and_pos.rank}}</h6></b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="border-right"><h6 class="mt-2 mb-4"><b-icon scale="2" icon="star-fill" class="mr-3" style="color: #46a9e8;"></b-icon>Puntaje en {{selected_years[0]}}: {{main_country_rank_and_pos.score}}</h6></b-col>
                                    <b-col class="border-left"><h6 class="mt-2 mb-4"><b-icon scale="2" icon="star-fill" class="mr-3" style="color: #AB85C3;"></b-icon>Puntaje en {{selected_years[1]}}: {{second_country_rank_and_pos.score}}</h6></b-col>
                                </b-row>
                            </div>
                            <button class="btn btn-outline-info" @click="getTopics()">Seleccionar</button>
                            <div v-if="first_selection_data && second_selection_data">
                                <b-row>
                                    <b-col ><h6>
                                    <b-form-select class="selector mt-2" v-model="selected_topic" :options="topics"></b-form-select>
                                    </h6></b-col>
                                </b-row>
                            </div>
                        </b-container>
                        <div v-if="first_selection_data && second_selection_data && selected_topic">
                            <base-bar :chart-data="bar_chart_data" :options="chart_options" id="barcom" class="mt-3"/>
                        </div>
                        <div v-else class="align-items-center text-center mt-5">
                            <b-spinner class="mt-5" variant="info"></b-spinner>
                        </div>
                        <h6 class="text-danger mt-4">Nota: Las variables que no posean barra será porque no han sido evaluadas en el año seleccionado.</h6>
                    </div>
                  </b-col>
                  <b-col class="border-left mt-3">
                    <div v-if="first_selection_data && second_selection_data">
                        <base-radar :chart-data="radar_chart_data" :options="chart_options" id="radarcom"/>
                    </div>
                    <div v-else class="align-items-center text-center mt-5">
                        <b-spinner class="mt-5" variant="info"></b-spinner>
                    </div>
                  </b-col>
              </b-row>
            </b-container>
        </div>
        <div v-else class="align-items-center text-center mt-5">
            <b-spinner class="mt-5" variant="info"></b-spinner>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import BaseBar from '../components/charts/BaseBar.js'
import BaseRadar from '../components/charts/BaseRadar.js'

export default {
    components: {
        BaseBar,
        BaseRadar
    },
    data() {
        return {
            base_url: process.env.VUE_APP_ROOT_API,
            countries_request: null,
            main_country_selected: "PAN",
            main_year_selected: "2020",
            secondary_country_selected: "PAN",
            secondary_year_selected: "2020",
            first_selection_data: null,
            second_selection_data: null,
            selected_topic: null,
            chart_options: {
                responsive: true, 
                maintainAspectRatio: false,
                scaleOverride: true,
                scaleSteps: 10, // number of ticks
                scaleStepWidth: 10, // tick interval
                scaleBeginAtZero: true
            },
            selected_countries: ["PAN", "PAN"],
            selected_years: ["2020", "2020"]
        }
    },
    computed: {
        country_options() {
            return [...new Set(this.countries_request.map(item => { 
                return JSON.stringify({text: item.economy, value: item.code})
            }))].sort().map(item => JSON.parse(item));
        },
        main_year_options() {
            return this.countries_request.filter(item => 
                    item.code === this.main_country_selected)
                .map(item => item.year).sort().reverse()
        },
        secondary_year_options() {
            return this.countries_request.filter(item => 
                    item.code === this.secondary_country_selected)
                .map(item => item.year).sort().reverse()
        },
        topics() {
            return [...new Set(this.first_selection_data
                    .filter(item => item.topic != "Facilidad de hacer negocios")
                    .map(item => item.topic)
                    .concat(this.second_selection_data
                    .filter(item => item.topic != "Facilidad de hacer negocios")
                    .map(item => item.topic)))].sort()
        },
        indicators() {
            return [...new Set(this.first_selection_data.filter(item =>
                    item.topic === this.selected_topic).map(item => item.indicator)
                    .concat(this.second_selection_data.filter(item => 
                    item.topic === this.selected_topic).map(item => item.indicator)))].sort()
                    .filter(item => item != "Score" && item != "Rank")
                    .filter(item => item.startsWith("Puntaje-"))
        },
        main_country_rank_and_pos() {
            let score = this.first_selection_data
                        .filter(item => item.indicator === "Score" && item.topic == "Facilidad de hacer negocios")
                        .map(item => item.value)[0]
            let rank = this.first_selection_data
                        .filter(item => item.indicator === "Rank" && item.topic == "Facilidad de hacer negocios")
                        .map(item => item.value)[0]
            return {score: score, rank: rank}
        },
        second_country_rank_and_pos() {
            let score = this.second_selection_data
                        .filter(item => item.indicator === "Score" && item.topic == "Facilidad de hacer negocios")
                        .map(item => item.value)[0]
            let rank = this.second_selection_data
                        .filter(item => item.indicator === "Rank" && item.topic == "Facilidad de hacer negocios")
                        .map(item => item.value)[0]
            return {score: score, rank: rank}
        },
        bar_chart_data() {
            return {
                labels: this.indicators.map(item => item.substring(8, item.indexOf("("))),
                datasets: [
                    {
                        label: this.selected_countries[0],
                        backgroundColor: "rgba(1, 116, 188, 0.6)",
                        data: this.getIndicatorValues(this.first_selection_data)
                    },
                    {
                        label: this.selected_countries[1],
                        backgroundColor: "rgba(63, 31, 149, 0.6)",
                        data: this.getIndicatorValues(this.second_selection_data)
                    }
                ]
            }
        },
        radar_chart_data() {
            return {
                labels: this.topics,
                datasets: [
                    {
                        label: this.selected_countries[0],
                        lineTension: 0.1,
                        backgroundColor: "rgba(1, 116, 188, 0.3)",
                        borderColor: "rgba(1, 116, 188, 0.5)",
                        pointBackgroundColor: "rgba(1, 116, 188, 0.5)",
                        pointBorderColor: "#fff",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "rgba(1, 116, 188, 0.7)",
                        data: this.first_selection_data.filter(item => item.indicator === "Score" && item.topic != "Facilidad de hacer negocios")
                                .sort((a, b) => (a.topic > b.topic) ? 1 : -1)
                                .map(item => item.value)
                    },
                    {
                        label: this.selected_countries[1],
                        lineTension: 0.1,
                        backgroundColor: "rgba(63, 31, 149, 0.3)",
                        borderColor: "rgba(63, 31, 149, 0.5)",
                        pointBackgroundColor: "rgba(63, 31, 149, 0.5)",
                        pointBorderColor: "#fff",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "rgba(63, 31, 149, 0.7)",
                        data: this.second_selection_data.filter(item => item.indicator === "Score" && item.topic != "Facilidad de hacer negocios")
                                .sort((a, b) => (a.topic > b.topic) ? 1 : -1)
                                .map(item => item.value)
                    }
                ]
            }
        }
    },
    created() {
        this.getCountries()
        this.getTopics()
    },
    methods: {
        getCountries(){
            axios.get(this.base_url + "/doingbusiness/countries")
                .then(response => {
                    this.countries_request = response.data
                })
        },
        getTopics() {
            axios.get(this.base_url + "/doingbusiness/topics/" + 
                        this.main_country_selected + "/" + this.main_year_selected)
                .then(response => {
                    this.first_selection_data = response.data
                    this.setDefaultTopic()
                })

            axios.get(this.base_url + "/doingbusiness/topics/" + 
                        this.secondary_country_selected + "/" + this.secondary_year_selected)
                .then(response => {
                    this.second_selection_data = response.data
                    this.setDefaultTopic()
                })

            this.selected_countries[0] = this.main_country_selected
            this.selected_countries[1] = this.secondary_country_selected
            this.selected_years[0] = this.main_year_selected
            this.selected_years[1] = this.secondary_year_selected
        },
        getIndicatorValues(data) {
            let indicator_values = []
            this.indicators.forEach(indicator => {
                let filtered_arr = data.filter(item => item.indicator === indicator && item.topic === this.selected_topic)
                if (filtered_arr.length == 1) {
                    indicator_values.push(parseFloat(filtered_arr[0].value).toFixed(2))
                } else {
                    indicator_values.push(Number.NaN)
                }
            })
            return indicator_values
        },
        setDefaultTopic() {
            if (this.first_selection_data && this.second_selection_data) {
                this.selected_topic = this.topics[0]
            }
        }
    }
}
</script>

<style scoped>
.selector{
    background-color: #F3F3F3;
}
.selector:hover{
  background-color: #DBDBDB;
}

@media (min-width: 1024px) and (max-width: 1366px) 

  {
    #barcom
      {
        height: 300px;
      }
  }

@media (min-width: 1900px) and (max-width: 1921px) 
{
#barcom
  {
    height: 500px;
  } 
  .selector
  {
      height: 45px;
  }
}

@media (min-width: 799px) and (max-width: 1367px) 

  {
    #radarcom
      {
        height: 500px;
      }
  }

@media (min-width: 1900px) and (max-width: 1921px) 
{
#radarcom
  {
    height: 850px;
  } 
}
</style>
