<template>
<b-card no-body >
    <!-- b-card-header -->
<b-card-header header-tag="header" class="p-2" role="tab">
<b-container class="bv-example-row">
        <b-row :class="score_changed">
                <b-col cols="9" class="text-left">
                <b-button block href="#" v-b-toggle.accordion-5 variant="info" class="tarjeta">Obtención de crédito</b-button>
                </b-col>
                <b-col>
                <b-form-input readonly v-bind:value="gc_score" class="text-center"></b-form-input>
                </b-col>
        </b-row>
</b-container>
</b-card-header>
<!-- b-card-header -->
<b-collapse id="accordion-5"  accordion="my-accordion" role="tabpanel">
    <b-card-body>
    <b-card-text>

        <b-container class="bv-example-row">
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Valor entre 0 y 8" :class="gc_cii_changed" cols="9" class="border-left text-left " >Índice de alcance de la información crediticia (0-8) </b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyNumberBetween($event, 8)" number trim @input="gc_cii_changed = 'changed'"  v-model.lazy="gc_cii" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Valor entre 0 y 12" :class="gc_lri_changed" cols="9" class="border-left text-left " >Índice de fortaleza de los derechos legales (0-12)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyNumberBetween($event, 12)" number trim @input="gc_lri_changed = 'changed'" v-model.lazy="gc_lri" class="text-center"></b-form-input>
                </b-col>
            </b-row>
        </b-container>

    </b-card-text>
    </b-card-body>
</b-collapse>
</b-card>
</template>

<script>
import { mapState } from 'vuex'
import filters from '../../libs/inputFilters'

export default {
    data() {
        return {
            gc_cii_changed: '',
            gc_lri_changed: ''
        }
    },
    methods: {
        ...filters
    },
    computed: {
        score_changed() {
            if (this.gc_cii_changed == 'changed' ||
                this.gc_lri_changed == 'changed')
                return 'changed'
            else return ''
        },
        gc_cii: {
            get() {
                return this.$store.state.gc_cii
            },
            set(value) {
                this.$store.commit('gc_cii', value)
            }
        },
        gc_lri: {
            get() {
                return this.$store.state.gc_lri
            },
            set(value) {
                this.$store.commit('gc_lri', value)
            }},
        gc_score() {
            if (isNaN(this.$store.getters.gc_score)) return ""
            return this.$store.getters.gc_score.toFixed(1)
        },
    }
}
</script>

<style scoped>
.tarjeta{
color: #5D5D5D;
font-weight: bold;
background-color: white;
text-align: left;
border-color: #81B3E8;
}
.variable{
font-weight: bold;
color: #5D5D5D;
}
@media (min-width: 1900px) and (max-width: 1921px) 
{
.tarjeta
{
    height: 70px;
}
}
.changed {
    background: lightcyan;
}
</style>