<template>
<div>
    <div role="tablist" class="mb-2">
     <b-card no-body>
      <b-card-header header-tag="header" class="p-1" role="tab" >
            <b-container fluid class="bv-example-row">
                <b-row>
                    <b-col cols="8">
                    <b-button block href="#" v-b-toggle="almacena_topicos.indicator" variant="info" class="tarjeta text-left" > {{almacena_topicos.indicator}} </b-button>    
                    </b-col>
                    <b-col class="border-right"><strong class="text-info">{{parseFloat(almacena_topicos.value).toFixed(2)}}</strong></b-col>
                </b-row>
            </b-container>
      </b-card-header>
     <b-collapse :id="almacena_topicos.indicator" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <b-container fluid class="bv-example-row">
                <b-col>
                    <b-row class="mb-2">
                        <b-col>
                          <ChartLine v-if="historiacargada" class="line-chart-height" :chartdata="datacollectionLinePTJ" :options="chartOptionsLinePTJ"></chartLine>
                        </b-col>
                </b-row>  
                </b-col>
            </b-container>
          </b-card-text>
        </b-card-body>
        </b-collapse>
        </b-card>
    </div>
</div>
</template>

<script>
import ChartLine from '../charts/BaseNRLine'

export default {
    name:'TopicCard',
    components:
    {
       ChartLine
    },
    data()
    {
      return{
        datacollectionLinePTJ: null,
        historiacargada: false,
        chartOptionsLinePTJ: {
              responsive: true,
              maintainAspectRatio: false
                    },
      }
    },
    props:[
            "almacena_topicos",
            "APIhistoria"
          ],
    created() 
    {
      let datos = []

        let fecha_topicoPTJ = this.APIhistoria.filter(item => item.topic == this.almacena_topicos.indicator).map(item => item.year).sort()
        let APIhistoriaPTJ = this.APIhistoria.filter(item => item.topic == this.almacena_topicos.indicator);
        for(let b = 0 ; b < fecha_topicoPTJ.length ; b++) {
          for(let d = 0 ; d < APIhistoriaPTJ.length ; d++) {
            if(APIhistoriaPTJ[d].year == fecha_topicoPTJ[b]) {
              datos.push(parseFloat(APIhistoriaPTJ[d].value).toFixed(2))
            }
          }
        }

        this.datacollectionLinePTJ = {
          labels: fecha_topicoPTJ,
          datasets: [
            {
              label: "Puntaje",
              backgroundColor: "transparent",
              borderColor: "rgba(63, 31, 149, 0.6)",
              pointBackgroundColor: "rgba(63, 31, 149, 1)",
              borderWidth: 5,
              pointRadius: 7,
              data: datos
            }
          ]
        }

        this.historiacargada = true
    },
}
</script>

<style scoped>
.tarjeta{
    color: #5D5D5D;
    font-weight: bold;
    background-color: white; 
    text-align: left;
     border-color: #81B3E8;
}
.line-chart-height {
  height: 300px;
}
</style>