<template>
    <div>
        <b-container  fluid class="bv-example-row border">
        <b-row class="border">
            <!-- Elementos estáticos en la pantalla principal (Visualización) -->
          <b-col cols="4" class=" mt-3" v-if="asignado">
            <b-container  fluid class="bv-example-row">
                    <b-col class="border-bottom border-left border-right">
                      <b-row class="mt-2">
                      <b-col cols="2" class="mt-2">
                        <h6>Indicador:</h6>
                      </b-col>
                      <b-col cols="10">
                        <b-form-select v-model="indice" :options="arr_indicadores" @change="limpiar()" class="selector mb-2"> <!-- select con los indicadores -->
                      </b-form-select>
                      </b-col>
                      </b-row>
                    </b-col>
                    <div class="w-100"></div>
                     <b-col class="border" >
                       <b-row>
                          <b-col cols="2" class="mt-2">
                            <h6>País:</h6>
                          </b-col>
                          <b-col cols="10" v-if="indice === 'doingbusiness'">
                            <b-form-select v-model="pais" required class="selector mt-1 mb-2">
                              <option v-for="(item, index) in APIcargarPais_db" v-bind:key="index" > <!-- cargado todos los paises ordenados -->
                                {{ item }}
                              </option>
                            </b-form-select>
                          </b-col>
                           <b-col v-else-if="indice === 'lpi'" cols="10">
                            <b-form-select v-model="pais_lpi" required class="selector mt-1 mb-2">
                              <option v-for="(item, index) in APIcargarPais_lpi" v-bind:key="index" > <!-- cargado todos los paises ordenados -->
                                {{ item }}
                              </option>
                            </b-form-select>
                          </b-col>
                          <b-col v-else-if="indice === 'gci4'" cols="10">
                            <b-form-select v-model="pais_gci4" required class="selector mt-1 mb-2">
                              <option v-for="(item, index) in APIcargarPais_gci4" v-bind:key="index" > <!-- cargado todos los paises ordenados -->
                                {{ item }}
                              </option>
                            </b-form-select>
                          </b-col>
                          <b-col v-else-if="indice === 'gii'" cols="10">
                            <b-form-select v-model="pais_gii" required class="selector mt-1 mb-2">
                              <option v-for="(item, index) in APIcargarPais_gii" v-bind:key="index" > <!-- cargado todos los paises ordenados -->
                                {{ item }}
                              </option>
                            </b-form-select>
                          </b-col>
                          <b-col v-else-if="indice === 'ttci'" cols="10">
                            <b-form-select v-model="pais_ttci" required class="selector mt-1 mb-2">
                              <option v-for="(item, index) in APIcargarPais_ttci" v-bind:key="index" > <!-- cargado todos los paises ordenados -->
                                {{ item }}
                              </option>
                            </b-form-select>
                          </b-col>
                          <b-col v-else-if="indice === 'efi'" cols="10">
                            <b-form-select v-model="pais_efi" required class="selector mt-1 mb-2">
                              <option v-for="(item, index) in APIcargarPais_efi" v-bind:key="index" > <!-- cargado todos los paises ordenados -->
                                {{ item }}
                              </option>
                            </b-form-select>
                          </b-col>
                          <b-col v-else cols="10">
                            <b-form-select v-model="pais_gci" required class="selector mt-1 mb-2">
                              <option v-for="(item, index) in APIcargarPais_gci" v-bind:key="index" > <!-- cargado todos los paises ordenados -->
                                {{ item }}
                              </option>
                            </b-form-select>
                          </b-col>
                        </b-row>
                    </b-col>
                    <div class="w-100"></div>
                    <b-col class="border">
                      <b-row>
                        <b-col cols="2" class="mt-2">
                          <h6>Fecha:</h6>
                        </b-col>
                          <b-col cols="10" v-if="indice === 'doingbusiness'">
                            <b-form-select v-model="fecha" required class="selector mb-4">
                              <option v-for="(item, index) in asignarFecha" v-bind:key="index" > <!-- for con las fechas en las que el pais seleccionado tiene data -->
                              {{ item }}
                              </option>
                            </b-form-select> 
                          </b-col>
                          <b-col cols="10" v-else-if="indice === 'lpi'">
                            <b-form-select v-model="fecha_lpi" required class="selector mb-4">
                                <option v-for="(item, index) in asignarFecha_lpi" v-bind:key="index" > <!-- for con las fechas en las que el pais seleccionado tiene data -->
                                {{ item }}
                                </option>
                              </b-form-select>
                          </b-col>
                          <b-col cols="10" v-else-if="indice === 'gci4'">
                            <b-form-select v-model="fecha_gci4" required class="selector mb-4">
                                <option v-for="(item, index) in asignarFecha_gci4" v-bind:key="index" > <!-- for con las fechas en las que el pais seleccionado tiene data -->
                                {{ item }}
                                </option>
                              </b-form-select>
                          </b-col>
                          <b-col cols="10" v-else-if="indice === 'gii'">
                            <b-form-select v-model="fecha_gii" required class="selector mb-4">
                                <option v-for="(item, index) in asignarFecha_gii" v-bind:key="index" > <!-- for con las fechas en las que el pais seleccionado tiene data -->
                                {{ item }}
                                </option>
                              </b-form-select>
                          </b-col>
                          <b-col cols="10" v-else-if="indice === 'ttci'">
                            <b-form-select v-model="fecha_ttci" required class="selector mb-4">
                                <option v-for="(item, index) in asignarFecha_ttci" v-bind:key="index" > <!-- for con las fechas en las que el pais seleccionado tiene data -->
                                {{ item }}
                                </option>
                              </b-form-select>
                          </b-col>
                          <b-col cols="10" v-else-if="indice === 'efi'">
                            <b-form-select v-model="fecha_efi" required class="selector mb-4">
                                <option v-for="(item, index) in asignarFecha_efi" v-bind:key="index" > <!-- for con las fechas en las que el pais seleccionado tiene data -->
                                {{ item }}
                                </option>
                              </b-form-select>
                          </b-col>
                          <b-col cols="10" v-else>
                            <b-form-select v-model="fecha_gci" required class="selector mb-4">
                                <option v-for="(item, index) in asignarFecha_gci" v-bind:key="index" > <!-- for con las fechas en las que el pais seleccionado tiene data -->
                                {{ item }}
                                </option>
                              </b-form-select>
                          </b-col>
                        </b-row>
                      </b-col>
                    <div class="w-100"></div>
                    <b-col class="border" v-if="pais && fecha && indice === 'doingbusiness'">
                      <b-button variant="outline-info" v-on:click="buscar" class="botonBuscar mb-3 mt-1">Buscar</b-button> <!-- botón para buscar el pais y año seleccionado -->
                    </b-col>
                    <b-col class="border" v-else-if="pais_lpi && fecha_lpi && indice === 'lpi'">
                      <b-button variant="outline-info" v-on:click="buscar" class="botonBuscar mb-3 mt-1">Buscar</b-button> <!-- botón para buscar el pais y año seleccionado -->
                    </b-col>
                    <b-col class="border" v-else-if="pais_gci && fecha_gci && indice === 'gci'">
                      <b-button variant="outline-info" v-on:click="buscar" class="botonBuscar mb-3 mt-1">Buscar</b-button> <!-- botón para buscar el pais y año seleccionado -->
                    </b-col>
                    <b-col class="border" v-else-if="pais_gci4 && fecha_gci4 && indice === 'gci4'">
                      <b-button variant="outline-info" v-on:click="buscar" class="botonBuscar mb-3 mt-1">Buscar</b-button> <!-- botón para buscar el pais y año seleccionado -->
                    </b-col>
                    <b-col class="border" v-else-if="pais_gii && fecha_gii && indice === 'gii'">
                      <b-button variant="outline-info" v-on:click="buscar" class="botonBuscar mb-3 mt-1">Buscar</b-button> <!-- botón para buscar el pais y año seleccionado -->
                    </b-col>
                    <b-col class="border" v-else-if="pais_ttci && fecha_ttci && indice === 'ttci'">
                      <b-button variant="outline-info" v-on:click="buscar" class="botonBuscar mb-3 mt-1">Buscar</b-button> <!-- botón para buscar el pais y año seleccionado -->
                    </b-col>
                    <b-col class="border" v-else-if="pais_efi && fecha_efi && indice === 'efi'">
                      <b-button variant="outline-info" v-on:click="buscar" class="botonBuscar mb-3 mt-1">Buscar</b-button> <!-- botón para buscar el pais y año seleccionado -->
                    </b-col>
                    <b-col class="border" v-else>
                      <span id="disabled-wrapper">
                        <b-button  disabled style="pointer-events: none;" variant="outline-info" v-on:click="buscar" class="botonBuscar mb-3 mt-1" >Buscar</b-button> <!-- botón para buscar el pais y año seleccionado -->
                          <b-popover target="disabled-wrapper" triggers="hover" variant="danger" placement="righttop">
                            Hacer selección de todos los campos para habilitar
                          </b-popover>
                      </span>
                    </b-col>
                    <div class="w-100"></div>
                        <b-col class="border">
                            <h4 class="mb-4"><strong class="text-success"> {{ nombre_indicador }} </strong>
                            <br><strong style="color:#0A6936;">{{almacena_pais}} {{almacena_fecha}} </strong></h4> <!-- muestra país seleccionado -->
                        </b-col>
                    <div class="w-100"></div>
                    <b-col class="border">
                        <h5 class="mb-4">Región: <br><strong>{{region}}</strong></h5> <!-- región del país seleccionado -->
                    </b-col>
                    <div class="w-100"></div>
                    <b-col>
                      <b-row>
                        <b-col class="border">
                          <h5 class="mt-4">Puntuación</h5>
                        </b-col>
                        <b-col class="border">
                          <b-button class="btn-xl" :pressed="true" variant="info">
                            <h6 class="centered"><strong>{{ parseFloat(almacena_ptj).toFixed(2)}}</strong></h6> <!-- puntuación del país seleccionado -->
                          </b-button>
                        </b-col>
                      </b-row>
                     </b-col>
                     <div class="w-100"></div>
                    <b-col>
                      <b-row>
                        <b-col class="border">
                          <h5 class="mt-4">Posición global</h5>
                        </b-col>
                        <b-col class="border">
                          <b-button @click="cincoGlobal" class="btn-xl" variant="outline-info" id="popover-target-global"> <!-- busca los 5 por encima y por debajo globalmente del país seleccionado  -->
                            <h6 class="centered"><strong>{{almacena_psc}} / {{totalGlobal}}</strong></h6> <!-- muestra la posición global -->
                          </b-button>
                          <b-popover target="popover-target-global" triggers="hover" placement="righttop">
                            <template v-slot:title>Posición global</template>
                            Presione para ver los 5 por encima y por debajo del país seleccionado.
                          </b-popover>
                        </b-col>
                      </b-row>
                    </b-col>
                    <div class="w-100"></div>
                    <b-col>
                        <b-row>
                        <b-col class="border-left border-right border-top mb-1">
                          <h5 class="mt-4">Posición regional</h5>
                        </b-col>
                        <b-col class="border-left border-right border-top mb-1">
                          <b-button @click="cincoRegional" class="btn-xl" variant="outline-info" id="popover-target-regional"> <!-- botón que busca los 5 por debajo y por encima regionalmente del país seleccionado -->
                            <h6 class="centered"><strong>{{posicionRegion}} / {{totalRegion}}</strong></h6> <!-- muestra la posición regional -->
                          </b-button>
                          <b-popover target="popover-target-regional" triggers="hover" placement="righttop">
                            <template v-slot:title>Posición regional</template>
                            Presione para ver los 5 por encima y por debajo del país seleccionado.
                          </b-popover>
                        </b-col>
                      </b-row>
                    </b-col>
            </b-container>
            </b-col>
            <b-col  class="border-left mt-4">
              <!-- Componente de los 5 países por encima y por debajo del país seleccionado -->
                <div v-if="asignado && cargado && cargado5top && cargadoHistoria">
                  <FiveTop :cincoArribaAbajo="cincoArribaAbajo" :almacena_pais="almacena_pais" :eleccion="eleccion"/> <!-- mostrando componente de 5 por encima y 5 por debajo -->
                </div>
                <div v-else class="align-items-center text-center mt-5">
                  <b-spinner class="mt-5" variant="info" style="width: 5rem; height: 5rem;"></b-spinner> <!-- spinner de espera -->
                </div>
            </b-col>
            <b-col cols="7" class="border-left mt-4" >
              <!-- Componente para llamado de la gráfica de RADAR-->
              <div v-if="cargado && cargadoHistoria && carga_radar">
                <chartRadar id="radarHome" :chart-data="radar_chart_data" :options="chartOptionsRadar"></chartRadar>
               <!-- <RenderRadar :extraeRankScore="extraeRankScore" class="mt-4" id="radarHome"/>  mostrando componente con gráfica de radar -->
              </div>
              <div v-else class="align-items-center text-center mt-5">
                <b-spinner class="mt-5" variant="info" style="width: 25rem; height: 25rem;"></b-spinner> <!-- spinner de espera -->
              </div>  
            </b-col>
            <div class="w-100"></div>  <!-- Salto a otra fila de columnas -->
            <b-col class="mt-1 mb-3 border-top"> <!-- Componente que muestras los mejores del mundo y de la región -->
              <div v-if="asignado && cargado && cargado5top && cargadoHistoria">
                <b-row class="text-left mb-3">
                  <b-col>
                    <b-row class="text-center">
                      <b-col class="text-secondary">
                    <h5 class="mt-4 mb-4">5 mejores países a nivel global</h5> 
                  </b-col>
                  <div class="w-100"></div> 
                      <b-col v-for=" (value, key, index) in cinco_mejores_global" :key="index">
                      <b-button class="cinco_mejores" :id="value.code+key" variant="success"><strong>{{key+1}}. {{value.code}}</strong>  </b-button> <!-- for con 5 -->
                        <b-popover :target="value.code+key" triggers="hover" placement="top" variant="success">
                        <template v-slot:title>{{value.economy}}</template>
                        Puntuación: {{parseFloat(value.score).toFixed(2)}} 
                        </b-popover>
                      </b-col>|
                    </b-row>
                  </b-col>
                  <b-col>
                  <b-row class="text-center">
                    <b-col class="text-secondary">
                      <h5 class="mt-4 mb-4">5 mejores países de {{region}} </h5> 
                    </b-col>
                    <div class="w-100"></div> 
                      <b-col v-for=" (value2, key2, index2) in cinco_mejores_regional" :key="index2">
                        <b-button class="cinco_mejores" :id="value2.economy" variant="success"><strong>{{key2+1}}. {{value2.code}}</strong></b-button> <!-- for con 5 -->
                        <b-popover :target="value2.economy" triggers="hover" placement="top" variant="success">
                          <template v-slot:title>{{value2.economy}}</template>
                          Puntuación: {{parseFloat(value2.score).toFixed(2)}}
                      </b-popover>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
              <div  v-else class="align-items-center text-center mt-5">
                <b-spinner class="mt-5" variant="info" style="width: 5rem; height: 5rem;"></b-spinner> 
              </div>
            </b-col>
             <div class="w-100"></div>  <!-- Salto a otra fila de columnas -->
            <b-col class="border-top mt-3">
              <!-- Componentes para llamado de las gráficas de LINEAS  -->
              <b-row>
                <b-col>
                    <div v-if="cargadoHistoriaPSC && asignado && cargado && cargadoHistoria && carga_radar && APIhistoriaPSC != null">
                      <ChartLineHome  id="linePSCHome" :chart-data="line_chart_data_psc" :options="chartOptionsLinePSC" class="mt-3"/> <!-- propiedad computada reemplazo -->
                      <!-- <ChartLineHome id="linePSCHome" :chart-data="datacollectionLinePSC" :options="chartOptionsLinePSC"></ChartLineHome> -->
                    </div>
                    <div v-else class="align-items-center text-center mt-5">
                      <b-spinner class="mt-5" variant="info" style="width: 15rem; height: 15rem;"></b-spinner>
                    </div>
                </b-col>
                 <b-col>
                    <div v-if="cargadoHistoriaPTJ && asignado && cargado && cargadoHistoria && carga_radar && APIhistoriaPTJ != null">
                      <ChartLineHome  id="linePTJHome" :chart-data="line_chart_data_ptj" :options="chartOptionsLinePTJ" class="mt-3"/> <!-- propiedad computada reemplazo -->
                      <!-- <ChartLineHome id="linePTJHome" :chart-data="datacollectionLinePTJ" :options="chartOptionsLinePTJ"></ChartLineHome> -->
                    </div>
                    <div v-else class="align-items-center text-center mt-5">
                      <b-spinner class="mt-5" variant="info" style="width: 15rem; height: 15rem;"></b-spinner>
                    </div>
                </b-col> 
              </b-row>
            </b-col>
            <div class="w-100"></div>  <!-- Salto a otra fila de columnas TOPICS CARD -->
            <b-col v-if="indice_buscado === 'gci'" class="border-top mt-3 mb-2">
              <b-container fluid class="bv-example-row mt-3" >
                    <b-row>
                        <b-col cols="8"></b-col>
                        <b-col><h6>Posición</h6></b-col>
                        <b-col><h6>Puntaje</h6></b-col>
                    </b-row>
                </b-container>
                <div v-if="asignado && cargadoHistoria">
                  <TopicCardGCI v-for="almacena_topicos in almacena_topicos" :almacena_topicos="almacena_topicos"
                  :key="almacena_topicos.id" :APIhistoria="APIhistoria"/> <!-- componente de los tópicos con sus puntajes y posiciones -->
                </div>
                <div v-else>
                  <b-spinner class="mt-5" variant="info" style="width: 15rem; height: 15rem;"></b-spinner>
                </div>
            </b-col>
            <b-col v-else-if="indice_buscado === 'gci4'" class="border-top mt-3 mb-2">
              <b-container fluid class="bv-example-row mt-3">
                    <b-row>
                        <b-col cols="6"></b-col>
                        <b-col><h6>Valor</h6></b-col>
                        <b-col><h6>Posición</h6></b-col>
                        <b-col><h6>Puntaje</h6></b-col>
                    </b-row>
                </b-container>
                <div v-if="asignado && cargadoHistoria">
                  <TopicCardGCI4 v-for="almacena_topicos in almacena_topicos" :almacena_topicos="almacena_topicos"
                  :key="almacena_topicos.id" :APIhistoria="APIhistoria"/> <!-- componente de los tópicos con sus puntajes y posiciones -->
                </div>
                <div v-else>
                  <b-spinner class="mt-5" variant="info" style="width: 15rem; height: 15rem;"></b-spinner>
                </div>
            </b-col>
            <b-col v-else-if="indice_buscado === 'gii'" class="border-top mt-3 mb-2">
              <b-container fluid class="bv-example-row mt-3">
                    <b-row>
                        <b-col cols="6"></b-col>
                        <b-col><h6>Posición</h6></b-col>
                        <b-col><h6>Puntaje</h6></b-col>
                    </b-row>
                </b-container>
                <div v-if="asignado && cargadoHistoria">
                   <TopicCardGII v-for="almacena_topicos in almacena_topicos" :almacena_topicos="almacena_topicos"
                   :key="almacena_topicos.id" :APIhistoria="APIhistoria"/> <!-- componente de los tópicos con sus puntajes y posiciones  -->
                </div>
                <div v-else>
                  <b-spinner class="mt-5" variant="info" style="width: 15rem; height: 15rem;"></b-spinner>
                </div>
            </b-col>
             <b-col v-else-if="indice_buscado === 'ttci'" class="border-top mt-3 mb-2">
              <b-container fluid class="bv-example-row mt-3">
                    <b-row>
                        <b-col cols="6"></b-col>
                        <b-col><h6>Posición</h6></b-col>
                        <b-col><h6>Puntaje</h6></b-col>
                    </b-row>
                </b-container>
                <div v-if="asignado && cargadoHistoria">
                   <TopicCardTTCI v-for="almacena_topicos in almacena_topicos" :almacena_topicos="almacena_topicos"
                   :key="almacena_topicos.id" :APIhistoria="APIhistoria"/> <!-- componente de los tópicos con sus puntajes y posiciones  -->
                </div>
                <div v-else>
                  <b-spinner class="mt-5" variant="info" style="width: 15rem; height: 15rem;"></b-spinner>
                </div>
            </b-col>
            <b-col v-else-if="indice_buscado === 'efi'" class="border-top mt-3 mb-2">
              <b-container fluid class="bv-example-row mt-3">
                    <b-row>
                        <b-col cols="8"></b-col>
                        <b-col><h6>Puntaje</h6></b-col>
                    </b-row>
                </b-container>
                <div v-if="asignado && cargadoHistoria">
                   <TopicCardEFI v-for="almacena_topicos in almacena_topicos" :almacena_topicos="almacena_topicos" 
                   :key="almacena_topicos.id" :APIhistoria="APIhistoria"/> <!-- componente de los tópicos con sus puntajes y posiciones  -->
                </div>
                <div v-else>
                  <b-spinner class="mt-5" variant="info" style="width: 15rem; height: 15rem;"></b-spinner>
                </div>
            </b-col>
            <b-col  v-else class="border-top mt-3 mb-2">
              <!-- Componente con tabla de los tópicos (dentro de este componente se llamará otros 2 componentes (tabla indicadores & gráfica de lineas))  -->
                <b-container fluid class="bv-example-row mt-3" >
                    <b-row>
                        <b-col cols="8"></b-col>
                        <b-col><h6>Posición</h6></b-col>
                        <b-col><h6>Puntaje</h6></b-col>
                        <b-col><h6>Mejor región</h6></b-col>
                        <b-col><h6>Mejor global</h6></b-col>
                    </b-row>
                </b-container>
                <div v-if="asignado && cargadoHistoria">
                  <TopicCard v-for="extraeRankScore in extraeRankScore"  :extraeRankScore="extraeRankScore" :key="extraeRankScore.id"
                  :APIhistoria="APIhistoria"/> <!-- componente de los tópicos con sus puntajes y posiciones -->
                </div>
                <div v-else>
                  <b-spinner class="mt-5" variant="info" style="width: 15rem; height: 15rem;"></b-spinner>
                </div>
            </b-col>
            
        </b-row>
    </b-container>
    </div>
</template>

<script>
import axios from "axios"; //importar la librería para trabajar la API
import FiveTop from '../components/visualization/FiveTop.vue' //importando componente
import TopicCard from '../components/visualization/TopicCard.vue' //importando componente
import TopicCardGCI from '../components/visualization/TopicCardGCI.vue' //importando componente
import TopicCardGCI4 from '../components/visualization/TopicCardGCI4.vue' //importando componente
import TopicCardGII from '../components/visualization/TopicCardGII.vue'
import TopicCardTTCI from '../components/visualization/TopicCardTTCI.vue'
import TopicCardEFI from '../components/visualization/TopicCardEFI.vue'
import chartRadar from '../components/charts/BaseRadar.js'
import ChartLineHome from '../components/charts/BaseLine.js'
export default {
    components: { //declaración de componentes
         FiveTop,
         TopicCard,
         TopicCardGCI,
         TopicCardGCI4,
         TopicCardGII,
         TopicCardTTCI,
         TopicCardEFI,
         chartRadar,
         ChartLineHome
    },
    data(){ //declaración de variables
    return{
      base_url: process.env.VUE_APP_ROOT_API,
      APIcargarPais:[],
      APIcargarPais_lpi:[],
      APIcargarPais_db:[],
      APIcargarPais_gci:[],
      APIcargarPais_gci4:[],
      APIcargarPais_gii:[],
      APIcargarPais_ttci:[],
      APIcargarPais_efi:[],
      APIcargarFecha:[],
      APIcargarFecha_lpi:[],
      APIcargarFecha_gci:[],
      APIcargarFecha_gci4:[],
      APIcargarFecha_gii:[],
      APIcargarFecha_ttci:[],
      APIcargarFecha_efi:[],
      APIcargarTopicos:[],
      APIcargarTopicosGCI_ranks:[],
      carga_topicos: [],
      APIScores:[],
      fechaPais:[],
      totalGlobal: '',
      Regiones:[], 
      mayor_global:[],
      mayor_regional:[],
      cinco_mejores_global:[],
      cinco_mejores_regional:[],
      totalRegion: '',
      region:'',
      posicionRegion:'',
      asignado: false, 
      cargado: false,
      cargado5top: false,
      eleccion: false,
      cargadoHistoria: false,
      cargadoHistoriaPSC: false,
      cargadoHistoriaPTJ: false,
      carga_radar:false,
      indice_buscado:'doingbusiness',
      almacena_pais:'',
      almacena_psc:'',
      almacena_ptj:'',
      almacena_fecha:'',
      almacena_fecha_chart:[],
      almacena_topicos:[],
      fecha: '2020',
      fecha_lpi:'',
      fecha_gii:'',
      fecha_gci:'',
      fecha_ttci:'',
      fecha_efi:'',
      fecha_gci4:'',
      pais:'Panamá',
      pais_lpi:'',
      pais_gii:'',
      pais_gci:'',
      pais_ttci:'',
      pais_efi:'',
      pais_gci4:'',
      codigo: 'PAN',
      indice: 'doingbusiness',
      sub_indice:'Facilidad de hacer negocios',
      nombre_indicador: 'Facilidad de hacer negocios',
      extraeRankScore: [],
      extraerIndicador: [],
      cincoArribaAbajo: [],
      APIhistoria:[],
      APIhistoriaPSC:[],
      APIhistoriaPTJ:[],
      radar_topics:[],
      radar_scores:[],
      line_data_psc:[],
      line_labels_psc:[],
      line_data_ptj:[],
      line_labels_ptj:[],
      topicos:[],
      chartOptionsRadar: {
          responsive: true,
          maintainAspectRatio: false
            },
      chartOptionsLinePSC: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [{
                  ticks: {                                    
                          reverse:true
                          }
                    }]
                }
              },
      chartOptionsLinePTJ: {
              responsive: true,
              maintainAspectRatio: false
                    },
      indices:["doingbusiness","lpi","gci", "gci4","gii","ttci","efi"],
      sub_indices:["Facilidad de hacer negocios","Overall Lpi","Global Competitiveness Index", "Global Competitiveness Index 4.0","Global Innovation Index","Travel & Tourism Competitiveness Index","Economic Freedom Index"]
    };
  },
  created(){ //cuando se carga la plataforma carga inicialmente 2 métodos que llaman a todos los demas
        this.almacena_pais = this.pais
        this.almacena_fecha = this.fecha
        this.cargarPais();
        this.cargarPais_lpi();
        this.cargarPais_gii(); 
        this.cargarPais_ttci();
        this.cargarPais_gci();
        this.cargarPais_gci4();
        this.cargarPais_efi();
        this.cargarPais_db();

        this.cargarFecha();
        this.cargarFecha_lpi();
        this.cargarFecha_gci();
        this.cargarFecha_ttci();
        this.cargarFecha_gci4();
        this.cargarFecha_gii();
        this.cargarFecha_efi();

    },
  computed:{
    line_chart_data_psc() {
      let datos = []
      let fechas = []
      if (this.indice_buscado == this.indice) {
        if (this.indice == "lpi" || this.indice == "doingbusiness") {
        fechas = this.fechaPais
      } else {
        fechas = this.almacena_fecha_chart
      }
        for(var b = 0 ; b < fechas.length ; b++){
          for(var c = 0 ; c < this.APIhistoriaPSC.length ; c++){
            if(this.APIhistoriaPSC[c].year == fechas[b]){
              datos.push(this.APIhistoriaPSC[c].value)
            }
          }
        }
        this.line_data_psc = datos
        this.line_labels_psc = fechas
      }
      else{
        this.line_data_psc = this.line_data_psc
        this.line_labels_psc = this.line_labels_psc
      }
        return{
        labels: this.line_labels_psc.reduce((ary, ele) => {ary.unshift(ele); return ary}, []),
          datasets: [
            {
              label: "Posición",
              backgroundColor: "transparent",
              borderColor: "rgba(1, 116, 188, 0.6)",
              pointBackgroundColor: "rgba(1, 116, 188, 1)",
              borderWidth: 5,
              pointRadius: 7,
              data: this.line_data_psc.reduce((ary, ele) => {ary.unshift(ele); return ary}, [])
            }
          ]
        }
      
        },
      line_chart_data_ptj() {
      let datos = []
      let fechas = []
      if (this.indice_buscado == this.indice) 
      {
          if (this.indice == "lpi" || this.indice == "doingbusiness") {
          fechas = this.fechaPais
        } else {
          fechas = this.almacena_fecha_chart
        }
          for(var b = 0 ; b < fechas.length ; b++){
            for(var d = 0 ; d < this.APIhistoriaPTJ.length ; d++){
              if(this.APIhistoriaPTJ[d].year == fechas[b]){
                datos.push(parseFloat(this.APIhistoriaPTJ[d].value).toFixed(2))
              }
            }
          }
        this.line_data_ptj = datos
        this.line_labels_ptj = fechas 
      }
      else
      {
        this.line_data_ptj = this.line_data_ptj
        this.line_labels_ptj = this.line_labels_ptj
      }
        return{
          labels: this.line_labels_ptj.reduce((ary, ele) => {ary.unshift(ele); return ary}, []),
          datasets: [
            {
              label: "Puntaje",
              backgroundColor: "transparent",
              borderColor: "rgba(63, 31, 149, 0.6)",
              pointBackgroundColor: "rgba(63, 31, 149, 1)",
              borderWidth: 5,
              pointRadius: 7,
              data: this.line_data_ptj.reduce((ary, ele) => {ary.unshift(ele); return ary}, [])
            }
          ]
        }
        },
      radar_chart_data() {
      let topicos=[]
      let puntaje=[]
      if (this.indice_buscado == this.indice) 
      {
        if (this.indice == "gci") {
        for(var i = 0; i < this.almacena_topicos.length ; i++){
              topicos.push(this.almacena_topicos[i].indicator)
              puntaje.push(parseFloat(this.almacena_topicos[i].value).toFixed(2))
          }
      }
      else if (this.indice == "gci4") {
        for(var i = 0; i < this.almacena_topicos.length ; i++){
              topicos.push(this.almacena_topicos[i].indicator)
              puntaje.push(parseFloat(this.almacena_topicos[i].value).toFixed(2))
          }
      }
      else if (this.indice == "gii") {
        for(var i = 0; i < this.almacena_topicos.length ; i++){
              topicos.push(this.almacena_topicos[i].pillar.indicator)
              puntaje.push(parseFloat(this.almacena_topicos[i].pillar.value).toFixed(2))
          }
      }
      else if (this.indice == "ttci") {
        for(var i = 0; i < this.almacena_topicos.length ; i++){
              topicos.push(this.almacena_topicos[i].pillar.indicator)
              puntaje.push(parseFloat(this.almacena_topicos[i].pillar.value).toFixed(2))
          }
      }
      else if (this.indice == "efi") {
        for(var i = 0; i < this.almacena_topicos.length ; i++){
              topicos.push(this.almacena_topicos[i].indicator)
              puntaje.push(parseFloat(this.almacena_topicos[i].value).toFixed(2))
          }
      }
      else if (this.indice == "lpi" || this.indice == "doingbusiness"){ 
          for(var i = 0; i < this.extraeRankScore.length ; i++){
                topicos.push(this.extraeRankScore[i].topico)
                puntaje.push(parseFloat(this.extraeRankScore[i].puntaje).toFixed(2))
            }
        }
        this.radar_topics = topicos
        this.radar_scores = puntaje
      }
      else 
      {
        this.radar_topics = this.radar_topics
        this.radar_scores = this.radar_scores
      }
      return{
          labels: this.radar_topics,
            datasets: [
              {
                label: "Puntaje",
                lineTension: 0.1,
                backgroundColor: "rgba(91, 166, 181 ,0.7)",
                borderColor: "rgba(91, 166, 181 ,0.5)",
                pointBackgroundColor: "rgba(91, 166, 181 ,0.7)",
                pointBorderColor: "#fff",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "rgba(91, 166, 181 ,0.7)",
                data: this.radar_scores
              }
            ]
          }
        },
    arr_indicadores(){
      let arr_valores_select = []
      let arr_sub = ["Facilidad de hacer negocios","Índice de desempeño logístico", "Índice de competitividad global", "Índice de competitividad global 4.0","Índice de innovación global","Índice de competitividad de viajes y turismo","Índice de libertad económica"]
      for(let a = 0 ; a < this.indices.length; a++){
        arr_valores_select.push({value:this.indices[a],text:arr_sub[a]})
      }
      return arr_valores_select
    },
    asignarFecha() //busca las fechas del país seleccionado
      {
      this.fechaPais = []
      for(var b = 0 ; b < this.APIcargarFecha.length ; b++){
        if(this.APIcargarFecha[b].economy == this.pais){
           this.fechaPais.push(this.APIcargarFecha[b].year)
        }
      }
      return this.fechaPais.sort().reverse()
    },
    asignarFecha_lpi() //busca las fechas del país seleccionado
      {
      this.fechaPais = []
      for(var b = 0 ; b < this.APIcargarFecha_lpi.length ; b++){
        if(this.APIcargarFecha_lpi[b].economy == this.pais_lpi){
           this.fechaPais.push(this.APIcargarFecha_lpi[b].year)
        }
      }
      return this.fechaPais.sort().reverse()
    },
    asignarFecha_gci() //busca las fechas del país seleccionado
      {
      this.fechaPais = []
      for(var b = 0 ; b < this.APIcargarFecha_gci.length ; b++){
        if(this.APIcargarFecha_gci[b].economy == this.pais_gci){
           this.fechaPais.push(this.APIcargarFecha_gci[b].year)
        }
      }
      return this.fechaPais.sort().reverse()
    },
    asignarFecha_gci4() //busca las fechas del país seleccionado
      {
      this.fechaPais = []
      for(var b = 0 ; b < this.APIcargarFecha_gci4.length ; b++){
        if(this.APIcargarFecha_gci4[b].economy == this.pais_gci4){
           this.fechaPais.push(this.APIcargarFecha_gci4[b].year)
        }
      }
      return this.fechaPais.sort().reverse()
    },
    asignarFecha_gii() //busca las fechas del país seleccionado
      {
      this.fechaPais = []
      for(var b = 0 ; b < this.APIcargarFecha_gii.length ; b++){
        if(this.APIcargarFecha_gii[b].economy == this.pais_gii){
           this.fechaPais.push(this.APIcargarFecha_gii[b].year)
        }
      }
      return this.fechaPais.sort().reverse()
    },
    asignarFecha_ttci() //busca las fechas del país seleccionado
      {
      this.fechaPais = []
      for(var b = 0 ; b < this.APIcargarFecha_ttci.length ; b++){
        if(this.APIcargarFecha_ttci[b].economy == this.pais_ttci){
           this.fechaPais.push(this.APIcargarFecha_ttci[b].year)
        }
      }
      return this.fechaPais.sort().reverse()
    },
    asignarFecha_efi() //busca las fechas del país seleccionado
      {
      this.fechaPais = []
      for(var b = 0 ; b < this.APIcargarFecha_efi.length ; b++){
        if(this.APIcargarFecha_efi[b].economy == this.pais_efi){
           this.fechaPais.push(this.APIcargarFecha_efi[b].year)
        }
      }
      return this.fechaPais.sort().reverse()
    }
  },
   methods:{
      buscar() //reacción al botón de buscar
      {
        this.cargado = false
        this.asignado = false
        this.cargado5top = false
        this.cargadoHistoria = false
        this.cargadoHistoriaPSC = false
        this.cargadoHistoriaPTJ = false
        if(this.indice == "doingbusiness"){
          this.sub_indice = this.sub_indices[0]
          this.nombre_indicador = "Facilidad de hacer negocios"
          this.almacena_pais = this.pais
          this.almacena_fecha = this.fecha
          this.indice_buscado = this.indice
        }
        else if(this.indice == "lpi"){
          this.sub_indice = this.sub_indices[1]
          this.nombre_indicador = "Índice de desempeño logístico"
          this.almacena_pais = this.pais_lpi
          this.almacena_fecha = this.fecha_lpi
           this.indice_buscado = this.indice
        }
        else if(this.indice == "gci4"){
          this.sub_indice = this.sub_indices[3]
          this.nombre_indicador = "Índice de competitividad global 4.0"
          this.almacena_pais = this.pais_gci4
          this.almacena_fecha = this.fecha_gci4
           this.indice_buscado = this.indice
          this.almacena_fecha_chart = this.fechaPais
        }
        else if(this.indice == "gii"){
          this.sub_indice = this.sub_indices[4]
          this.nombre_indicador = "Índice de innovación global"
          this.almacena_pais = this.pais_gii
          this.almacena_fecha = this.fecha_gii
           this.indice_buscado = this.indice
          this.almacena_fecha_chart = this.fechaPais
        }
        else if(this.indice == "ttci"){
          this.sub_indice = this.sub_indices[5]
          this.nombre_indicador = "Índice de competitividad de viajes y turismo"
          this.almacena_pais = this.pais_ttci
          this.almacena_fecha = this.fecha_ttci
           this.indice_buscado = this.indice
          this.almacena_fecha_chart = this.fechaPais
        }
        else if(this.indice == "efi"){
          this.sub_indice = this.sub_indices[6]
          this.nombre_indicador = "Índice de libertad económica"
          this.almacena_pais = this.pais_efi
          this.almacena_fecha = this.fecha_efi
           this.indice_buscado = this.indice
          this.almacena_fecha_chart = this.fechaPais
        }
        else{
          this.sub_indice = this.sub_indices[2]
          this.nombre_indicador = "Índice de competitividad global"
          this.almacena_pais = this.pais_gci
          this.almacena_fecha = this.fecha_gci
           this.indice_buscado = this.indice
          this.almacena_fecha_chart = this.fechaPais
        }
        this.cargarFecha();
        this.cargarFecha_lpi();
        this.cargarFecha_gci();
        this.cargarFecha_gii();
        this.cargarFecha_ttci();
        this.cargarFecha_efi();

        this.cargarPais();
        this.cargarPais_lpi();
        this.cargarPais_db();
        this.cargarPais_gci();
        this.cargarPais_gii();
        this.cargarPais_ttci();
        this.cargarPais_efi();
      },
    cargarPais(){ // cargando los datos del país en la fecha seleccionada
      this.APIcargarPais=[];
      axios.get(this.base_url + "/"+this.indice+"/ranks/"+this.almacena_fecha).then((res)=>{
        this.APIcargarPais = res.data.filter(item => item.rank != null);
        this.asignarPais()
        this.cargarMayores()
      })
      .catch(err => {
        console.log(err);
      })
    },
    cargarPais_lpi(){ // cargando los datos del país en la fecha seleccionada
      this.APIcargarPais_lpi=[];
      axios.get(this.base_url + "/lpi/ranks/2018").then((res)=>{
      this.APIcargarPais_lpi = res.data.map(item => item.economy).sort();
      })
      .catch(err => {
        console.log(err);
      })
    },
    cargarPais_db(){ // cargando los datos del país en la fecha seleccionada
      this.APIcargarPais_db=[];
      axios.get(this.base_url + "/doingbusiness/ranks/2020").then((res)=>{
      this.APIcargarPais_db = res.data.map(item => item.economy).sort();
      })
      .catch(err => {
        console.log(err);
      })
    },
    cargarPais_gci(){ // cargando los datos del país en la fecha seleccionada
      this.APIcargarPais_gci=[];
      axios.get(this.base_url + "/gci/ranks/2017-2018").then((res)=>{
      this.APIcargarPais_gci = res.data.map(item => item.economy).sort();
      })
      .catch(err => {
        console.log(err);
      })
    },
    cargarPais_gci4(){ // cargando los datos del país en la fecha seleccionada
      this.APIcargarPais_gci4=[];
      axios.get(this.base_url + "/gci4/ranks/2019").then((res)=>{
      this.APIcargarPais_gci4 = res.data.map(item => item.economy).sort();
      })
      .catch(err => {
        console.log(err);
      })
    },
    cargarPais_gii(){ // cargando los datos del país en la fecha seleccionada
      this.APIcargarPais_gii=[];
      axios.get(this.base_url + "/gii/ranks/2020").then((res)=>{
      this.APIcargarPais_gii = res.data.map(item => item.economy).sort();
      })
      .catch(err => {
        console.log(err);
      })
    },
    cargarPais_ttci(){ // cargando los datos del país en la fecha seleccionada
      this.APIcargarPais_ttci=[];
      axios.get(this.base_url + "/ttci/ranks/2019").then((res)=>{
      this.APIcargarPais_ttci = res.data.map(item => item.economy).sort();
      })
      .catch(err => {
        console.log(err);
      })
    },
    cargarPais_efi(){ // cargando los datos del país en la fecha seleccionada
      this.APIcargarPais_efi=[];
      axios.get(this.base_url + "/efi/ranks/2020").then((res)=>{
      this.APIcargarPais_efi = res.data.map(item => item.economy).sort();
      })
      .catch(err => {
        console.log(err);
      })
    },
    cargarFecha() //cargando las fechas de los que existe data de cada país
     {
        this.APIcargarFecha=[];
        axios.get(this.base_url + "/doingbusiness/countries").then((res)=>{
        this.APIcargarFecha = res.data;
        })
        .catch(err => {
        console.log(err);
        })
    },
    cargarFecha_lpi() //cargando las fechas de los que existe data de cada país
     {
        this.APIcargarFecha_lpi=[];
        axios.get(this.base_url + "/lpi/countries").then((res)=>{
        this.APIcargarFecha_lpi = res.data;
        })
        .catch(err => {
        console.log(err);
        })
    },
    cargarFecha_gci() //cargando las fechas de los que existe data de cada país
     {
        this.APIcargarFecha_gci=[];
        axios.get(this.base_url + "/gci/countries").then((res)=>{
        this.APIcargarFecha_gci = res.data;
        })
        .catch(err => {
        console.log(err);
        })
    },
    cargarFecha_gci4() //cargando las fechas de los que existe data de cada país
     {
        this.APIcargarFecha_gci4=[];
        axios.get(this.base_url + "/gci4/countries").then((res)=>{
        this.APIcargarFecha_gci4 = res.data;
        })
        .catch(err => {
        console.log(err);
        })
    },
    cargarFecha_gii() //cargando las fechas de los que existe data de cada país
     {
        this.APIcargarFecha_gii=[];
        axios.get(this.base_url + "/gii/countries").then((res)=>{
        this.APIcargarFecha_gii = res.data;
        })
        .catch(err => {
        console.log(err);
        })
    },
    cargarFecha_ttci() //cargando las fechas de los que existe data de cada país
     {
        this.APIcargarFecha_ttci=[];
        axios.get(this.base_url + "/ttci/countries").then((res)=>{
        this.APIcargarFecha_ttci = res.data;
        })
        .catch(err => {
        console.log(err);
        })
    },
    cargarFecha_efi() //cargando las fechas de los que existe data de cada país
     {
        this.APIcargarFecha_efi=[];
        axios.get(this.base_url + "/efi/countries").then((res)=>{
        this.APIcargarFecha_efi = res.data;
        })
        .catch(err => {
        console.log(err);
        })
    },
    cargarHitoria(){ //carga las puntuaciones de los distintos años del país seleccionado
      axios.get(this.base_url + "/"+this.indice+"/history/"+this.codigo).then((res)=>{
        this.APIhistoria = res.data.filter(item => item.topic != this.sub_indice );
        this.cargadoHistoria = true
      })
      .catch(err => {
        console.log(err);
      })
    },
    cargarHitoriaPSC(){ //carga las puntuaciones de los distintos años del país seleccionado
      axios.get(this.base_url + "/"+this.indice+"/history/"+this.codigo).then((res)=>{
        this.APIhistoriaPSC = res.data.filter(item => item.topic == this.sub_indice  && item.indicator == "Rank");
         this.cargadoHistoriaPSC = true
      })
      .catch(err => {
        console.log(err);
      })
    },
    cargarHitoriaPTJ(){ //carga las puntuaciones de los distintos años del país seleccionado
      axios.get(this.base_url + "/"+this.indice+"/history/"+this.codigo).then((res)=>{
        this.APIhistoriaPTJ = res.data.filter(item => item.topic == this.sub_indice && item.indicator == "Score");
        this.cargadoHistoriaPTJ = true
      })
      .catch(err => {
        console.log(err);
      })
    },
    cargarMayores() //carga todas las puntuaciones para sacar la mayor puntuación global y regional
    {
        this.APIScores=[];
        axios.get(this.base_url + "/"+this.indice+"/scores/"+this.almacena_fecha).then((res)=>{
        this.APIScores = res.data;
        this.mayorGlobalRegional();
      })
      .catch(err => {
        console.log(err);
      })
    },
    cargarTopicosLPI_DB(){ //carga los tópicos con el código del país como referencia y la fecha seleccionada
      axios.get(this.base_url + "/"+this.indice+"/topics/"+this.codigo+"/"+this.almacena_fecha).then((res)=>{
        this.APIcargarTopicos = res.data.filter(item => item.topic != this.sub_indice);
        let topicosAPI = this.APIcargarTopicos.filter(item => item.topic != this.sub_indice).map(item => item.topic).sort()
        this.topicos = topicosAPI.filter(function(value, index, self) { 
          return self.indexOf(value) === index;
        });
        this.cargarHitoria()
        this.cargarHitoriaPSC()
        this.cargarHitoriaPTJ()
      })
      .catch(err => {
        console.log(err);
      })
    },
    cargartopicosOTHERS()
    {
      axios.get(this.base_url + "/"+this.indice+"/topics/"+this.codigo+"/"+this.almacena_fecha).then((res)=>{
      if (this.indice == "gci") {
      let ranksGCI = res.data.filter(item => item.attribute == "Rank")
      this.APIcargarTopicosGCI_ranks = ranksGCI
      let scoresGCI = res.data.filter(item => item.attribute == "Score")
      this.carga_topicos = this.getChildren("top", scoresGCI)
      }
      else if (this.indice == "gci4") {
        this.carga_topicos = this.getChildrenByOrder(res.data)
      }
      else if (this.indice == "gii") {
        this.carga_topicos = this.getChildrenGII(res.data)
      }
      else if(this.indice == "ttci") {
        this.carga_topicos = this.getChildrenTTCI(res.data)
      } 
      else if (this.indice == "efi") {
        this.carga_topicos = res.data.filter(item => item.indicator != "Economic Freedom Index")
      }
      this.almacena_topicos = this.carga_topicos
      this.cargarHitoria()
       this.carga_radar = true
      })
      .catch(err => {
        console.log(err);
      })
    },
    getChildrenTTCI(data)
    {
      let dat = data, datos = [], pillars = [], subpillars = [], indicators = []
      let ranks = data.filter(item => item.attribute == "Rank")
      let key = data.filter(item => item.type == "Key")
      let scores = data.filter(item => item.attribute == "Score" && item.type != "Key")

      for (var index = 0; index < scores.length; index++) {
        if(scores[index].type == "Pillar"){
          pillars.push({indicator: scores[index].indicator, value: scores[index].value, rank: ranks[index].value})
        }
      }
       for(var a = 0 ; a < pillars.length; a++){
          for (var b = 0; b < scores.length; b++) {
            if (pillars[a].indicator == scores[b].belongsto){
              for (var c = 0; c < scores.length; c++) {
                if (scores[c].belongsto == scores[b].indicator) {
                  indicators.push({indicator: scores[c].indicator, value: scores[c].value, rank: ranks[c].value, type: scores[c].type})
                }
              }
              subpillars.push({indicator: scores[b].indicator, value: scores[b].value, rank: ranks[b].value, type: scores[b].type, indicators: indicators})
              indicators = []
            }
          }
          datos.push({pillar: pillars[a],subs: subpillars})
          subpillars = []
        }
      return datos
    },
    getChildrenGII(data)
    {
      let pillars = data
      let cons = 32, topicos=[], subpillars=[], datos = []
      let ranks = pillars.filter(item => item.attribute == "Rank")
      let scores = pillars.filter(item => item.attribute == "Score")

        for (var c = 0; c < scores.length; c++) {
          if (scores[c].indicator.charCodeAt(1) == cons) {
            topicos.push({ indicator: scores[c].indicator, rank: ranks[c].value, value: scores[c].value, type: "pillar"})
          }
          else if (scores[c].indicator.charCodeAt(3) == cons){
            topicos.push({ indicator: scores[c].indicator, rank: ranks[c].value, value: scores[c].value, type: "subpillar"})
          }
          else if (scores[c].indicator.charCodeAt(5) == cons){
            topicos.push({ indicator: scores[c].indicator, rank: ranks[c].value, value: scores[c].value, type: "subsubpillar"})
          }
        }
       let pillar = topicos.filter(item => item.type == "pillar")
       let b = 0
       for(var a = 1 ; a < topicos.length ; a++){
          if (topicos[a].type != "pillar" && topicos[a].indicator.substr(0,1) == pillar[b].indicator.substr(0,1)) {
            subpillars.push(topicos[a])
          }
          else{
            datos.push({pillar: pillar[b], subs: subpillars})
            subpillars = []
            b++
          }
          if (a+1 == topicos.length){
            datos.push({pillar: pillar[b], subs: subpillars})
          }
        }
      return datos
    },
    getChildren(parentName, dataArray) { 
        let parents = dataArray.filter(item => item.belongsto == parentName)
        
        parents.forEach((item) => {
                item.rank =  this.APIcargarTopicosGCI_ranks.filter(x => x.indicator == item.indicator)[0].value
                let children = this.getChildren(item.indicator, dataArray)
                
                if (children === undefined || children.length == 0 || children == null) {
                        return     
                } else { 
                        item.children = children
                }
        })
          return parents
      },
    getChildrenByOrder(data) {
      let pillars = data.filter(item => item.type == "Pillar" && item.attribute == "Score").sort((a, b) => a.order - b.order)

      for (let step = 0; step < pillars.length; step++) {
        pillars[step].rank = data.filter(item => item.attribute == "Rank" && item.indicator == pillars[step].indicator)[0].value

        if (step < pillars.length - 1) {
          pillars[step].children = data.filter(item => item.attribute == "Score" &&
                                                item.order > pillars[step].order &&
                                                item.order < pillars[step + 1].order).sort((a, b) => a.order - b.order)
        } else {
          pillars[step].children = data.filter(item => item.attribute == "Score" &&
                                                item.order > pillars[step].order).sort((a, b) => a.order - b.order)
        }

        pillars[step].children.forEach(item => {
          item.rank = data.filter(obj => obj.attribute == "Rank" && obj.indicator == item.indicator)[0].value
          item.score = item.value 
          if (item.type == "Indicator") {
            item.value = data.filter(obj => obj.attribute == "Value" && obj.indicator == item.indicator)[0].value
          } else {
            item.value == null
          }
        })
      }

      return pillars
    },
    asignarPais() //carga arreglo con los países
    {
        let paisesOrden = []
        let extraerPais = []
        for(var a = 0 ; a < this.APIcargarPais.length ; a++)
        {
          extraerPais.push(this.APIcargarPais[a].economy)
          if(this.APIcargarPais[a].economy == this.almacena_pais)
          {
            this.almacena_psc = this.APIcargarPais[a].rank 
            this.almacena_ptj = this.APIcargarPais[a].score
          }
        }
        paisesOrden = extraerPais.sort()
        this.totalGlobal = paisesOrden.length
        this.totalRegional();
    },
    totalRegional(){ //carga arreglo con el total de los países que estan en la región del país seleccionado
      this.Regiones = []
      this.cinco_mejores_regional = []
        for(var b = 0 ; b < this.APIcargarPais.length ; b++)
        {
          if(this.APIcargarPais[b].economy == this.almacena_pais)
          {
            this.region = this.APIcargarPais[b].region
            this.codigo = this.APIcargarPais[b].code
            for(var c = 0 ; c < this.APIcargarPais.length ; c++)
              {
                  if(this.APIcargarPais[c].region == this.region)
                  {
                    this.Regiones.push(this.APIcargarPais[c])
                  }
              }
          } 
        }

        this.totalRegion = this.Regiones.length
        for(var d = 0 ; d < this.totalRegion ; d++)
        {
          if(this.Regiones[d].economy == this.almacena_pais)
          {
            this.posicionRegion = d+1
          }
        }
          if(this.totalRegion <= 4)
          {
            for(var a = 0 ; a < this.Regiones.length ; a++)
              {
                this.cinco_mejores_regional.push(this.Regiones[a])
              }

          }
          else
          {
              for(var a = 0 ; a < 5 ; a++)
              {
                this.cinco_mejores_regional.push(this.Regiones[a])
              }
          }
    this.cargarTopicosLPI_DB();
    this.cargartopicosOTHERS();
    },
    extraerTopicos() // extrae la puntuación y la posición del país seleccionado separandolo de los demás indicadores
    {
        let rango, puntaje, contador
        this.extraeRankScore = [];
        for(var d = 0 ; d < this.topicos.length ; d++)
        {
          for(var e = 0 ; e < this.APIcargarTopicos.length ; e++)
            {
                if(this.APIcargarTopicos[e].topic == this.topicos[d])
                {
                    if(this.APIcargarTopicos[e].indicator == "Rank")
                    {
                        rango = this.APIcargarTopicos[e].value
                    }
                    else if(this.APIcargarTopicos[e].indicator == "Score")
                    {
                        puntaje = this.APIcargarTopicos[e].value
                    }
                    else 
                    {
                        if(isNaN(this.APIcargarTopicos[e].value))
                        {
                        var valor = {indicador: this.APIcargarTopicos[e].indicator, numero: this.APIcargarTopicos[e].value}
                        this.extraerIndicador.push(valor)
                        }
                        else
                        {
                        var mi_numero = this.APIcargarTopicos[e].value
                        var valor = {indicador: this.APIcargarTopicos[e].indicator, numero: mi_numero }
                        this.extraerIndicador.push(valor)
                        }
                        
                    }
                }
            }
            var valores = {topico: this.topicos[d], posicion: rango, puntaje: puntaje, 
            indicadores: this.extraerIndicador, mayorGlobal: this.mayor_global[d].valor, paisGlobal: this.mayor_global[d].pais , 
            mayorRegional: this.mayor_regional[d].valor, paisRegional: this.mayor_regional[d].pais , contador: d.toString()} 
            this.extraeRankScore.push(valores)
            this.extraerIndicador = []
        }
        this.cargado = true
        this.asignado = true
        this.cincoGlobal()
    },
    cincoGlobal() //carga los 5 por encima y 5 por debajo de manera global
    {
      this.eleccion = false
      this.cargado5top = false
      this.cincoArribaAbajo = []
      this.cinco_mejores_global = []
      for(var f = 0 ; f < this.APIcargarPais.length ; f++)
        {
          if(this.APIcargarPais[f].economy == this.almacena_pais)
          {
            if(f < 5)
            {
              for(var g = 0 ; g < 11 ; g++)
              {
                  var datos = { general: this.APIcargarPais[g]}
                  this.cincoArribaAbajo.push(datos)
              }
            }
            else if(f > (this.APIcargarPais.length-5))
            {
              for(var g = (this.APIcargarPais.length-11) ; g < this.APIcargarPais.length ; g++)
              {
                  var datos = { general: this.APIcargarPais[g]}
                  this.cincoArribaAbajo.push(datos)
              }
            }
            else
            {
              for(var g = f-5 ; g < f ; g++)
              {
                  var datos = { general: this.APIcargarPais[g]}
                  this.cincoArribaAbajo.push(datos)
              }
              var datos = { general: this.APIcargarPais[f]}
              this.cincoArribaAbajo.push(datos)
              for(var g = f+1 ; g <= f+5 ; g++)
              {
                var datos = { general: this.APIcargarPais[g]}
                this.cincoArribaAbajo.push(datos)
              }
            }
          } 
        }
        for(var a = 0 ; a < 5 ; a++)
        {
          this.cinco_mejores_global.push(this.APIcargarPais[a])
        }
        this.cargado5top = true
    },
    cincoRegional() //carga los 5 por encima y 5 por debajo de manera regional
    {
      this.eleccion = true
      this.cargado5top = false
      this.cincoArribaAbajo = [];
      for(var f = 0 ; f < this.Regiones.length ; f++)
        {
          if(this.Regiones[f].economy == this.almacena_pais)
          {
            if(this.Regiones.length < 11)
            {
              for(var g = 0 ; g < this.Regiones.length ; g++)
              {
                  var datos = { general: this.Regiones[g], rankRegion: g+1}
                  this.cincoArribaAbajo.push(datos)
              }
            }
            else
            {
              if(f < 5)
              {
                for(var g = 0 ; g < 11 ; g++)
                {
                    var datos = { general: this.Regiones[g], rankRegion: g+1}
                    this.cincoArribaAbajo.push(datos)
                }
              }
              else if(f > (this.Regiones.length-5))
              {
                  for(var g = (this.Regiones.length-11) ; g < this.Regiones.length ; g++)
                    {
                      var datos = { general: this.Regiones[g], rankRegion: g+1}
                      this.cincoArribaAbajo.push(datos)
                    }
              }
              else
              {
                for(var g = f-5 ; g < f ; g++)
                  {
                    var datos = { general: this.Regiones[g], rankRegion: g+1}
                    this.cincoArribaAbajo.push(datos)
                  }
                    var datos = { general: this.Regiones[f], rankRegion: f+1}
                    this.cincoArribaAbajo.push(datos)
                for(var g = f+1 ; g <= f+5 ; g++)
                  {
                    var datos = { general: this.Regiones[g], rankRegion: g+1}
                    this.cincoArribaAbajo.push(datos)
                  }
              }
              
            }
            
          } 
        }
        this.cargado5top = true
    },
    mayorGlobalRegional() // busca los mayores globales y regionales
    {
      this.mayor_global = []
      this.mayor_regional = []
      let temporal = 0
      let economia
      for(var i = 0 ; i < this.topicos.length ; i++)
      {
        for(var j = 0 ; j < this.APIScores.length ; j++)
        {
          if(this.APIScores[j].topic == this.topicos[i])
          {
            if(this.APIScores[j].value > temporal)
            {
              temporal = this.APIScores[j].value
              economia = this.APIScores[j].economy
            }
            else if(this.APIScores[j].value == 100)
            {
              temporal = 100
              economia = this.APIScores[j].economy
            } 
          }
        }
        var datos = {topico: this.topicos[i] , valor: temporal, pais : economia} 
        this.mayor_global.push(datos)
        temporal=0
      }
      for(var i = 0 ; i < this.topicos.length ; i++)
      {
        for(var j = 0 ; j < this.APIScores.length ; j++)
        {
          if(this.APIScores[j].topic == this.topicos[i] && this.APIScores[j].region == this.region)
          {
            if(this.APIScores[j].value > temporal)
            {
              temporal = this.APIScores[j].value
              economia = this.APIScores[j].economy
            }
            else if(this.APIScores[j].value == 100)
            {
              temporal = 100
              economia = this.APIScores[j].economy
            }
          }
        }
        var datos = {topico: this.topicos[i] , valor: temporal, pais : economia} 
        this.mayor_regional.push(datos)
        temporal=0
      }
      this.extraerTopicos()
    },
    limpiar()
    {
      this.pais = ''
      this.pais_lpi = ''
      this.pais_gci = ''
      this.pais_gci4 = ''
      this.pais_gii = ''
      this.fecha = ''
      this.fecha_lpi = ''
      this.fecha_gci = ''
      this.fecha_gci4 = ''
      this.fecha_gii = ''
    }
  }
};
</script>

<style scoped>
.btn-xl{
   width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 24px;
    border-width: 2px;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.selector{
  width: 80%;
  background-color: #F3F3F3;
}
.botonBuscar{
  width: 50%;
}
.cinco_mejores{
  width: 100%;
  background-color: #5DBD89;
  border: none;
}
.selector:hover{
  background-color: #DBDBDB;
}
@media (min-width: 1138px) and (max-width: 1366px) 
  {
    #radarHome
      {
        height: 620px;
      }
  }
  @media (min-width: 1900px) and (max-width: 1921px) 
  {
    #radarHome
      {
        height: 640px;
      }
  }
</style>

