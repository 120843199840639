export default {
    lib: (value, best, worst) => {
      let abs = worst - best

      value = parseFloat(value)
        if (value == -1) {
          return 0
        } else if (value > worst) {
          return 0
        } else if (value < best) {
          return 1 * 100
        } else {
          return ((worst - value) / abs) * 100
        }
    },
    index_score: (value, highest) => {
      if (value == "No Practice")
        return 0
      else return (parseFloat(value) / highest) * 100
    },
    hib: (value, best, worst) => {
      let abs = best - worst

      value = parseFloat(value)
      if (value == -1) {
        return 0
      } else if (value < worst) {
        return 0
      } else if (value > best) {
        return 1 * 100
      } else {
        return ((value - worst) / abs) * 100
      }
    },
}