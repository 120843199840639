<template>
    <div v-if="countries_request">
    <div class="about mt-3">
        <b-container  fluid class="bv-example-row " >
            <b-row>
            <b-col >
                <div class="simulacion">
                <b-container  fluid class="bv-example-row" >
                <b-row>
                <b-col>
                <b-form-select  id="btn1" class="form-control " v-model="selected_country" :options="country_options"></b-form-select>
                </b-col>
                <b-col>
                <button type="button" class="btn btn-outline-info float-right" @click="getIndicatorsData()" >Seleccionar</button>
                </b-col>
                </b-row>
                <div v-if="country_data">
                    <b-row>
                    <b-col  >
                    <h5><b-icon scale="1.5" class="mt-3 mr-2" icon="bookmark-fill" style="color: rgba(1, 116, 188, 0.5);"></b-icon>Posición actual: {{posicion_actual}}</h5>
                    </b-col>
                    <b-col  >
                    <h5><b-icon scale="1.5" class="mt-3 mr-2" icon="bookmark-fill" style="color: rgba(63, 31, 149, 0.5);"></b-icon>Posición simulada: {{posicion_simulada}}</h5>
                    </b-col>
                    </b-row>
                    <b-row>
                    <b-col>
                    <h5><b-icon scale="1.5" class="mr-2 mt-2" icon="star-fill" style="color: rgba(1, 116, 188, 0.5);"></b-icon>Puntaje actual: {{puntaje_actual}}</h5>
                    </b-col>
                    <b-col  >
                    <h5><b-icon scale="1.5" class="mr-2 mt-2" icon="star-fill" style="color: rgba(63, 31, 149, 0.5);"></b-icon>Puntaje simulado: {{puntaje_simulado}}</h5>
                    </b-col>
                    </b-row>
                    <base-radar class="mt-3" :chart-data="radar_chart_data" :options="chart_options" id="radarcom"/>
                </div>
                <div v-else class="align-items-center text-center mt-5">
                    <b-spinner class="mt-5" variant="info"></b-spinner>
                </div>
                </b-container>
                </div>
            </b-col>
            <b-col class="border-left">
                <div v-if="country_data" class="tablassimu">
                    <b-container fluid class="bv-example-row">
                    <b-row >
                    <b-col ><h5>Facilidad para hacer negocios en: <strong>{{reqCountryName}}</strong></h5></b-col>
                    <div class="w-100"></div>
                    <b-col cols="9"></b-col>
                    <b-col class=" mr-4">Puntaje</b-col>
                    <div class="w-100"></div>
                    <b-col >
                        <sab></sab>
                        <dwcp></dwcp>
                        <ge></ge>
                        <regp></regp>
                        <gc></gc> <!--arreglar -->
                        <pmi></pmi> <!--arreglar -->
                        <pt></pt> <!--arreglar -->
                        <tab></tab>
                        <ec></ec>
                    <ri></ri>
                    </b-col>
                    </b-row>
                    </b-container>
                </div>
                <div v-else class="align-items-center text-center mt-5">
                    <b-spinner class="mt-5" variant="info"></b-spinner>
                </div>
            </b-col>
            </b-row>
        </b-container>
    </div>
    </div>
    <div v-else class="align-items-center text-center mt-5">
        <b-spinner class="mt-5" variant="info"></b-spinner>
    </div>
</template>

<script>
import sab from "../components/simulation/starting_a_business.vue"
import dwcp from "../components/simulation/dealing_with_construction_permits.vue"
import ge from "../components/simulation/getting_electricity.vue"
import regp from "../components/simulation/registering_property.vue"
import gc from "../components/simulation/getting_credit.vue"
import pmi from "../components/simulation/protecting_minority_investors.vue"
import pt from "../components/simulation/paying_taxes.vue"
import tab from "../components/simulation/trading_across_borders.vue"
import ec from "../components/simulation/enforcing_contracts.vue"
import ri from "../components/simulation/resolving _insolvency.vue"
import axios from 'axios';
import setStoreData  from '../libs/setStoreData'
import BaseRadar from '../components/charts/BaseRadar.js'

export default {
    components: {
        sab,
        dwcp,
        ge,
        regp,
        gc,
        pmi,
        pt,
        tab,
        ec,
        ri,
        BaseRadar
    },
    data() {
        return {
            base_url: process.env.VUE_APP_ROOT_API,
            countries_request: null,
            selected_country: "PAN",
            requested_country: null,
            country_data: null,
            chart_options: {
                responsive: true, 
                maintainAspectRatio: false,
            },
            ranks: null
        }
    },
    created() {
        this.getCountries()
        this.getRanks()
        this.getIndicatorsData()
    },
    computed: {
        country_options() {
            return [...new Set(this.countries_request.map(item => {
                // la razon por la que se hace esto es porque se quiere
                // transformar a string para que solo haya un objeto con
                // propiedades similares, entonces pasandolo a string
                // y despues parseandolo
                return JSON.stringify({text: item.economy, value: item.code})
            }))].sort().map(item => JSON.parse(item));
        },
        reqCountryName() {
            return this.countries_request.filter(item => item.code == this.requested_country)[0].economy
        },
        simulated_scores() {
            return [
                this.$store.getters.dwcp_score.toFixed(1),
                this.$store.getters.ec_score.toFixed(1),
                this.$store.getters.gc_score.toFixed(1),
                this.$store.getters.ge_score.toFixed(1),
                this.$store.getters.pt_score.toFixed(1),
                this.$store.getters.pmi_score.toFixed(1),
                this.$store.getters.rp_score.toFixed(1),
                this.$store.getters.ri_score.toFixed(1),
                this.$store.getters.sab_score.toFixed(1),
                this.$store.getters.tab_score.toFixed(1),
            ]
        },
        actual_scores() {
            return [
                this.country_data.filter(item => item.topic == "Manejo de permisos de construcción" && item.indicator == "Score")[0].value,
                this.country_data.filter(item => item.topic == "Cumplimiento de contratos" && item.indicator == "Score")[0].value,
                this.country_data.filter(item => item.topic == "Obtención de crédito" && item.indicator == "Score")[0].value,
                this.country_data.filter(item => item.topic == "Obtención de Electricidad" && item.indicator == "Score")[0].value,
                this.country_data.filter(item => item.topic == "Pago de impuestos" && item.indicator == "Score")[0].value,
                this.country_data.filter(item => item.topic == "Protección de los inversionistas minoritarios" && item.indicator == "Score")[0].value,
                this.country_data.filter(item => item.topic == "Registro de propiedades" && item.indicator == "Score")[0].value,
                this.country_data.filter(item => item.topic == "Resolución de la insolvencia" && item.indicator == "Score")[0].value,
                this.country_data.filter(item => item.topic == "Apertura de un negocio" && item.indicator == "Score")[0].value,
                this.country_data.filter(item => item.topic == "Comercio transfronterizo" && item.indicator == "Score")[0].value,
            ]
        },
        posicion_actual() {
            return this.country_data.filter(item => item.topic == "Facilidad de hacer negocios" && item.indicator == "Rank")[0].value
        },
        posicion_simulada() {
            if (this.ranks) {
                let ranks_copy = JSON.parse(JSON.stringify(this.ranks))
                let ranks_index = ranks_copy.findIndex(obj => obj.code == this.requested_country)
                ranks_copy[ranks_index].score = parseInt(this.puntaje_simulado)
                let descending = ranks_copy.sort((item1, item2) => {return parseInt(item2.score) - parseInt(item1.score)})
                let descending_index = descending.findIndex(obj => obj.code == this.requested_country)
                return descending_index + 1
            } else return 0
        },
        puntaje_actual() {
            return this.country_data.filter(item => item.topic == "Facilidad de hacer negocios" && item.indicator == "Score")[0].value
        },
        puntaje_simulado() {
            return this.$store.getters.db_score.toFixed(1)
        },
        radar_chart_data() {
            return {
                labels: [
                    "Manejo de permisos de construcción",
                    "Cumplimiento de contratos",
                    "Obtención de crédito",
                    "Obtención de Electricidad",
                    "Pago de impuestos",
                    "Protección de los inversionistas minoritarios",
                    "Registro de propiedades",
                    "Resolución de la insolvencia",
                    "Apertura de un negocio",
                    "Comercio transfronterizo",
                ],
                datasets: [
                    {
                        label: "Puntaje Actual",
                        lineTension: 0.1,
                        backgroundColor: "rgba(1, 116, 188, 0.3)",
                        borderColor: "rgba(1, 116, 188, 0.5)",
                        pointBackgroundColor: "rgba(1, 116, 188, 0.5)",
                        pointBorderColor: "#fff",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "rgba(1, 116, 188, 0.7)",
                        data: this.actual_scores
                    },
                    {
                        label: "Puntaje Simulado",
                        lineTension: 0.1,
                        backgroundColor: "rgba(63, 31, 149, 0.3)",
                        borderColor: "rgba(63, 31, 149, 0.5)",
                        pointBackgroundColor: "rgba(63, 31, 149, 0.5)",
                        pointBorderColor: "#fff",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "rgba(63, 31, 149, 0.7)",
                        data: this.simulated_scores
                    }
                ]
            }
        }
    },
    methods: {
        getCountries() {
            axios.get(this.base_url + "/doingbusiness/countries")
                .then(response => {
                    this.countries_request = response.data
                })
        },
        getIndicatorsData() {
            this.country_data = null

            axios.get(this.base_url + "/doingbusiness/topics/"+this.selected_country+"/2020")
                .then(response => {
                    let data = response.data
                    setStoreData.set_sab_store_data(this.$store, data)
                    setStoreData.set_dwcp_store_data(this.$store, data)
                    setStoreData.set_ge_store_data(this.$store, data)
                    setStoreData.set_regp_store_data(this.$store, data)
                    setStoreData.set_gc_store_data(this.$store, data)
                    setStoreData.set_pmi_store_data(this.$store, data)
                    setStoreData.set_pt_store_data(this.$store, data)
                    setStoreData.set_tab_store_data(this.$store, data)
                    setStoreData.set_ec_store_data(this.$store, data)
                    setStoreData.set_ri_store_data(this.$store, data)

                    this.country_data = data
                })
            
            this.requested_country = this.selected_country
        },
        getRanks() {
            axios.get(this.base_url + "/doingbusiness/ranks/2020")
                .then(response => {
                    this.ranks = response.data
                })
        }
    }
}
</script>

<style scoped>
#btn1{
font-weight: 600;
}
#btn2 {
font-weight: 600;
float:right;
}
#btn2:hover{
background: lightblue;
}
#btn1:hover{
background: #F3F3F3;
}

h5{
text-align: left;
margin-left: 1%;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
color: #5D5D5D;
}

@media (min-width: 1024px) and (max-width: 1366px) 
{
.tablassimu
{
    overflow-y: scroll; height:620px;
}
}
@media (min-width: 1900px) and (max-width: 1921px) 
{
.tablassimu
    {
        overflow-y: scroll; height:920px;
    }
}
@media (min-width: 1024px) and (max-width: 1366px) 
  {
    #radarcom
      {
        height: 500px;
      }
  }

@media (min-width: 1900px) and (max-width: 1921px) 
{
#radarcom
  {
    height: 800px;
  } 
}

</style>