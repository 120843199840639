<template>
<b-card no-body >
    <!-- b-card-header -->
<b-card-header header-tag="header" class="p-2" role="tab">
<b-container class="bv-example-row">
        <b-row :class="score_changed">
                <b-col cols="9" class="text-left">
                <b-button block href="#" v-b-toggle.accordion-2 variant="info" class="tarjeta">Manejo de permisos de construcción</b-button>
                </b-col>
                <b-col>
                <b-form-input readonly v-bind:value="dwcp_score" class="text-center"></b-form-input>
                </b-col>
        </b-row>
</b-container>
</b-card-header>
<!-- b-card-header -->
<b-collapse id="accordion-2"  accordion="my-accordion" role="tabpanel">
    <b-card-body>
    <b-card-text>

        <b-container class="bv-example-row">
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="dwcp_proc_changed" cols="9" class="border-left text-left " >Procedimientos(número)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="dwcp_proc_changed = 'changed'" v-model.lazy="dwcp_proc" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="dwcp_time_changed" cols="9" class="border-left text-left " >Tiempo(días)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="dwcp_time_changed = 'changed'" v-model.lazy="dwcp_time" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="dwcp_cost_changed" cols="9" class="border-left text-left " >Costo (% del valor de la construcción)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="dwcp_cost_changed = 'changed'" v-model.lazy="dwcp_cost" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Valor entre 0 y 15; Escriba NP si no se practica" :class="dwcp_bqci_changed" cols="9" class="border-left text-left " >Índice de control de calidad de la construcción (0-15)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyNumberBetweenAndNP($event, 15)" number trim @input="dwcp_bqci_changed = 'changed'" v-model.lazy="dwcp_bqci" class="text-center"></b-form-input>
                </b-col>
            </b-row>
        </b-container>

    </b-card-text>
    </b-card-body>
</b-collapse>
</b-card>
</template>

<script>
import { mapState } from 'vuex'
import filters from '../../libs/inputFilters'

export default {
    data() {
        return {
            dwcp_proc_changed: '',
            dwcp_time_changed: '',
            dwcp_cost_changed: '',
            dwcp_bqci_changed: '',
        }
    },
    methods: {
        ...filters
    },
    computed: {
        score_changed() {
            if (this.dwcp_bqci_changed == 'changed' ||
                this.dwcp_cost_changed == 'changed' ||
                this.dwcp_time_changed == 'changed' ||
                this.dwcp_proc_changed == 'changed')
                return 'changed'
            else return ''
        },
        dwcp_proc: {
            get() {
                return this.$store.state.dwcp_proc
            },
            set(value) {
                this.$store.commit('dwcp_proc', value)
            }
        },
        dwcp_time: {
            get() {
                return this.$store.state.dwcp_time
            },
            set(value) {
                this.$store.commit('dwcp_time', value)
            }},
        dwcp_cost: {
            get() {
                return this.$store.state.dwcp_cost
            },
            set(value) {
                this.$store.commit('dwcp_cost', value)
            }
        },
        dwcp_bqci: {
            get() {
                if (this.$store.state.dwcp_bqci === "No Practice") 
                    return "NP"
                else return this.$store.state.dwcp_bqci
            },
            set(value) {
                if (value == "NP") value = "No Practice"
                this.$store.commit('dwcp_bqci', value)
            }
        },
        dwcp_score() {
            if (isNaN(this.$store.getters.dwcp_score)) return ""
            return this.$store.getters.dwcp_score.toFixed(1)
        },
    }
}
</script>

<style scoped>
.tarjeta{
color: #5D5D5D;
font-weight: bold;
background-color: white;
text-align: left;
border-color: #81B3E8;
}
.variable{
font-weight: bold;
color: #5D5D5D;
}
@media (min-width: 1900px) and (max-width: 1921px) 
{
.tarjeta
{
    height: 70px;
}
}
.changed {
    background: lightcyan;
}
</style>
