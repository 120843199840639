<template>
<b-card no-body >
    <!-- b-card-header -->
<b-card-header header-tag="header" class="p-2" role="tab">
<b-container class="bv-example-row">
        <b-row :class="score_changed">
                <b-col cols="9" class="text-left">
                <b-button block href="#" v-b-toggle.accordion-7 variant="info" class="tarjeta">Pago de impuestos</b-button>
                </b-col>
                <b-col>
                <b-form-input readonly v-bind:value="pt_score" class="text-center"></b-form-input>
                </b-col>
        </b-row>
</b-container>
</b-card-header>
<!-- b-card-header -->
<b-collapse id="accordion-7"  accordion="my-accordion" role="tabpanel">
    <b-card-body>
    <b-card-text>

        <b-container class="bv-example-row">
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="pt_payments_changed" cols="9" class="border-left text-left " >Pagos (número por año) </b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="pt_payments_changed = 'changed'" v-model.lazy="pt_payments" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="pt_time_changed" cols="9" class="border-left text-left " >Tiempo (horas por año)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="pt_time_changed = 'changed'" v-model.lazy="pt_time" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="pt_ttr_changed" cols="9" class="border-left text-left " >Tasa de impuestos y contribuciones total (% de ganancia)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="pt_ttr_changed = 'changed'" v-model.lazy="pt_ttr" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más alto es mejor" :class="pt_pfi_changed" cols="9" class="border-left text-left ">Índice posterior a la declaración de impuestos (0-100)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyNumberBetween($event, 100)" number trim @input="pt_pfi_changed = 'changed'" v-model.lazy="pt_pfi" class="text-center"></b-form-input>
                </b-col>
            </b-row>
        </b-container>

    </b-card-text>
    </b-card-body>
</b-collapse>
</b-card>
</template>

<script>
import { mapState } from 'vuex'
import filters from '../../libs/inputFilters'

export default {
    data() {
        return {
            pt_payments_changed: '',
            pt_time_changed: '',
            pt_ttr_changed: '',
            pt_tcvr_changed: '',
            pt_tovr_changed: '',
            pt_t_comply_cita_changed: '',
            pt_t_complete_cita_changed: '',
            pt_pfi_changed: ''
        }
    },
    methods: {
        ...filters
    },
    computed: {
        score_changed() {
            if (this.pt_payments_changed == 'changed' ||
                this.pt_time_changed == 'changed' ||
                this.pt_ttr_changed == 'changed' ||
                this.pt_tovr_changed == 'changed' ||
                this.pt_t_comply_cita_changed == 'changed' ||
                this.pt_pfi_changed == 'changed' ||
                this.pt_tcvr_changed == 'changed' ||
                this.pt_t_complete_cita_changed == 'changed')
                return 'changed'
            else return ''
        },
        pt_payments: {
            get() {
                return this.$store.state.pt_payments
            },
            set(value) {
                this.$store.commit('pt_payments', value)
            }
        },
        pt_time: {
            get() {
                return this.$store.state.pt_time
            },
            set(value) {
                this.$store.commit('pt_time', value)
            }
        },
        pt_ttr: {
            get() {
                return this.$store.state.pt_ttr
            },
            set(value) {
                this.$store.commit('pt_ttr', value)
            }
        },
        pt_pfi: {
            get() {
                return this.$store.state.pt_pfi
            },
            set(value) {
                this.$store.commit('pt_pfi', value)
            }
        },
        pt_tcvr: {
            get() {
                return this.$store.state.pt_tcvr
            },
            set(value) {
                this.$store.commit('pt_tcvr', value)
            }
        },
        pt_tovr: {
            get() {
                return this.$store.state.pt_tovr
            },
            set(value) {
                this.$store.commit('pt_tovr', value)
            }},
        pt_t_comply_cita: {
            get() {
                return this.$store.state.pt_t_comply_cita
            },
            set(value) {
                this.$store.commit('pt_t_comply_cita', value)
            }
        },
        pt_t_complete_cita: {
            get() {
                return this.$store.state.pt_t_complete_cita
            },
            set(value) {
                this.$store.commit('pt_t_complete_cita', value)
            }},
        pt_score() {
            if (isNaN(this.$store.getters.pt_score)) return ""
            return this.$store.getters.pt_score.toFixed(1)
        },
    }
}
</script>

<style scoped>
.tarjeta{
color: #5D5D5D;
font-weight: bold;
background-color: white;
text-align: left;
border-color: #81B3E8;
}
.variable{
font-weight: bold;
color: #5D5D5D;
}
@media (min-width: 1900px) and (max-width: 1921px) 
{
.tarjeta
{
    height: 70px;
}
}
.changed {
    background: lightcyan;
}
</style>