<template>
<b-card no-body >
    <!-- b-card-header -->
<b-card-header header-tag="header" class="p-2" role="tab">
<b-container class="bv-example-row">
        <b-row :class="score_changed">
                <b-col cols="9" class="text-left">
                <b-button block href="#" v-b-toggle.accordion-10 variant="info" class="tarjeta">Resolución de la insolvencia</b-button>
                </b-col>
                <b-col>
                <b-form-input readonly v-bind:value="ri_score" class="text-center"></b-form-input>
                </b-col>
        </b-row>
</b-container>
</b-card-header>
<!-- b-card-header -->
<b-collapse id="accordion-10"  accordion="my-accordion" role="tabpanel">
    <b-card-body>
    <b-card-text>

        <b-container class="bv-example-row">
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más alto es mejor" :class="ri_rr_changed" cols="9" class="border-left text-left " >Tasa de recuperación (centavos de dólar)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="ri_rr_changed = 'changed'" v-model.lazy="ri_rr" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Valor entre 0 y 16" :class="ri_sifi_changed" cols="9" class="border-left text-left " >Índice de la fortaleza del marco regulatorio de la insolvencia (0-16)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyNumberBetween($event, 16)" number trim @input="ri_sifi_changed = 'changed'" v-model.lazy="ri_sifi" class="text-center"></b-form-input>
                </b-col>
            </b-row>
        </b-container>

    </b-card-text>
    </b-card-body>
</b-collapse>
</b-card>
</template> 

<script>
import { mapState } from 'vuex'
import filters from '../../libs/inputFilters'

export default {
    data() {
        return {
            ri_rr_changed: '',
            ri_sifi_changed: ''
        }
    },
    methods: {
        ...filters
    },
    computed: {
        score_changed() {
            if (this.ri_rr_changed == 'changed' ||
                this.ri_sifi_changed == 'changed')
                return 'changed'
            else return ''
        },
        ri_rr: {
            get() {
                return this.$store.state.ri_rr
            },
            set(value) {
                this.$store.commit('ri_rr', value)
            }
        },
        ri_sifi: {
            get() {
                return this.$store.state.ri_sifi
            },
            set(value) {
                this.$store.commit('ri_sifi', value)
            }},
        ri_score() {
            if (isNaN(this.$store.getters.ri_score)) return ""
            return this.$store.getters.ri_score.toFixed(1)
        },
    }
}
</script>

<style scoped>
.tarjeta{
color: #5D5D5D;
font-weight: bold;
background-color: white;
text-align: left;
border-color: #81B3E8;
}
.variable{
font-weight: bold;
color: #5D5D5D;
}
@media (min-width: 1900px) and (max-width: 1921px) 
{
.tarjeta
{
    height: 70px;
}
}
.changed {
    background: lightcyan;
}
</style>