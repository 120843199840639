<template>
<b-card no-body >
    <!-- b-card-header -->
<b-card-header header-tag="header" class="p-2" role="tab">
<b-container class="bv-example-row">
        <b-row :class="score_changed">
                <b-col cols="9" class="text-left">
                <b-button block href="#" v-b-toggle.accordion-8 variant="info" class="tarjeta">Comercio transfronterizo</b-button>
                </b-col>
                <b-col>
                <b-form-input readonly v-bind:value="tab_score" class="text-center"></b-form-input>
                </b-col>
        </b-row>
</b-container>
</b-card-header>
<!-- b-card-header -->
<b-collapse id="accordion-8"  accordion="my-accordion" role="tabpanel">
    <b-card-body>
    <b-card-text>

        <b-container class="bv-example-row">
           <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="tab_ttebc_changed" cols="9" class="border-left text-left " >Tiempo para exportar: Cumplimiento fronterizo (horas)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="tab_ttebc_changed = 'changed'" v-model.lazy="tab_ttebc" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="tab_ttedc_changed" cols="9" class="border-left text-left " >Tiempo para exportar: Cumplimiento documental (horas)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="tab_ttedc_changed = 'changed'" v-model.lazy="tab_ttedc" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="tab_ctebc_changed" cols="9" class="border-left text-left " >Costo para exportar: Cumplimiento fronterizo (USD)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="tab_ctebc_changed = 'changed'" v-model.lazy="tab_ctebc" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="tab_ctedc_changed" cols="9" class="border-left text-left " >Costo para exportar: Cumplimiento documental (USD)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="tab_ctedc_changed = 'changed'" v-model.lazy="tab_ctedc" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="tab_ttibc_changed" cols="9" class="border-left text-left " >Tiempo para importar: Cumplimiento fronterizo (horas)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="tab_ttibc_changed = 'changed'" v-model.lazy="tab_ttibc" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="tab_ttidc_changed" cols="9" class="border-left text-left " >Tiempo para importar: Cumplimiento documental (horas)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="tab_ttidc_changed = 'changed'" v-model.lazy="tab_ttidc" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="tab_ctibc_changed" cols="9" class="border-left text-left " >Costo para importar: Cumplimiento fronterizo (USD)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="tab_ctibc_changed = 'changed'" v-model.lazy="tab_ctibc" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="tab_ctidc_changed" cols="9" class="border-left text-left " >Costo para importar: Cumplimiento documental (USD)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="tab_ctidc_changed = 'changed'" v-model.lazy="tab_ctidc" class="text-center"></b-form-input>
                </b-col>
            </b-row>
        </b-container>

    </b-card-text>
    </b-card-body>
</b-collapse>
</b-card>
</template>

<script>
import { mapState } from 'vuex'
import filters from '../../libs/inputFilters'

export default {
    data() {
        return {
            tab_ttebc_changed: '',
            tab_ttedc_changed: '',
            tab_ctebc_changed: '',
            tab_ctedc_changed: '',
            tab_ttibc_changed: '',
            tab_ttidc_changed: '',
            tab_ctibc_changed: '',
            tab_ctidc_changed: ''
        }
    },
    methods: {
        ...filters
    },
    computed: {
        score_changed() {
            if (this.tab_ttebc_changed == 'changed' ||
                this.tab_ttedc_changed == 'changed' ||
                this.tab_ctebc_changed == 'changed' ||
                this.tab_ctedc_changed == 'changed' ||
                this.tab_ttibc_changed == 'changed' ||
                this.tab_ttidc_changed == 'changed' ||
                this.tab_ctibc_changed == 'changed' ||
                this.tab_ctidc_changed == 'changed')
                return 'changed'
            else return ''
        },
        tab_ttebc: {
            get() {
                return this.$store.state.tab_ttebc
            },
            set(value) {
                this.$store.commit('tab_ttebc', value)
            }
        },
        tab_ttedc: {
            get() {
                return this.$store.state.tab_ttedc
            },
            set(value) {
                this.$store.commit('tab_ttedc', value)
            }
        },
        tab_ctebc: {
            get() {
                return this.$store.state.tab_ctebc
            },
            set(value) {
                this.$store.commit('tab_ctebc', value)
            }
        },
        tab_ctedc: {
            get() {
                return this.$store.state.tab_ctedc
            },
            set(value) {
                this.$store.commit('tab_ctedc', value)
            }},
        tab_ttibc: {
            get() {
                return this.$store.state.tab_ttibc
            },
            set(value) {
                this.$store.commit('tab_ttibc', value)
            }
        },
        tab_ttidc: {
            get() {
                return this.$store.state.tab_ttidc
            },
            set(value) {
                this.$store.commit('tab_ttidc', value)
            }},
        tab_ctibc: {
            get() {
                return this.$store.state.tab_ctibc
            },
            set(value) {
                this.$store.commit('tab_ctibc', value)
            }
        },
        tab_ctidc: {
            get() {
                return this.$store.state.tab_ctidc
            },
            set(value) {
                this.$store.commit('tab_ctidc', value)
            }
        },
        tab_score() {
            if (isNaN(this.$store.getters.tab_score)) return ""
            return this.$store.getters.tab_score.toFixed(1)
        },
    }
}
</script>

<style scoped>
.tarjeta{
color: #5D5D5D;
font-weight: bold;
background-color: white;
text-align: left;
border-color: #81B3E8;
}
.variable{
font-weight: bold;
color: #5D5D5D;
}
@media (min-width: 1900px) and (max-width: 1921px) 
{
.tarjeta
{
    height: 70px;
}
}
.changed {
    background: lightcyan;
}
</style>