<template>
  <div id="app">
    <nav-bar></nav-bar>
    <router-view></router-view>
  </div>
</template>

<script>
import NavBar from './components/layout/NavBar.vue'

export default {
  name: 'app',
  components: {
    'nav-bar': NavBar
  },
  computed: {
  },
  created () {
  },
}
</script>

<style >
#app {
  text-align: center;
  color: #2c3e50;
}
</style>

