<template>
<div>
<b-card no-body >
    <!-- b-card-header -->
<b-card-header header-tag="header" class="p-2" role="tab">
<b-container class="bv-example-row">
        <b-row :class="score_changed">
                <b-col cols="9" class="text-left">
                <b-button block href="#" v-b-toggle.accordion-1 variant="info" class="tarjeta">Apertura de un negocio</b-button>
                </b-col>
                <b-col>
                <b-form-input readonly v-bind:value="sab_score" class="text-center"></b-form-input>
                </b-col>
        </b-row>
</b-container>
</b-card-header>
<!-- b-card-header -->
<b-collapse id="accordion-1"  accordion="my-accordion" role="tabpanel">
    <b-card-body>
    <b-card-text>

        <b-container class="bv-example-row">
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="sab_proc_men_changed" cols="9" class="border-left text-left " >Procedimientos - Hombres (número)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="sab_proc_men_changed = 'changed'" v-model.lazy="sab_proc_men" class="text-center campo "></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="sab_time_men_changed" cols="9" class="border-left text-left " >Tiempo - Hombres (días)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="sab_time_men_changed = 'changed'" v-model.lazy="sab_time_men" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="sab_cost_men_changed" cols="9" class="border-left text-left " >Costo - Hombres (% de INB per cápita)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="sab_cost_men_changed = 'changed'" v-model.lazy="sab_cost_men" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="sab_proc_women_changed" cols="9" class="border-left text-left " >Procedimientos - Mujeres (número)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="sab_proc_women_changed = 'changed'" v-model.lazy="sab_proc_women" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="sab_time_women_changed" cols="9" class="border-left text-left " >Tiempo - Mujeres (días)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="sab_time_women_changed = 'changed'" v-model.lazy="sab_time_women" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="sab_cost_women_changed" cols="9" class="border-left text-left " >Costo - Mujeres (% de INB per cápita)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="sab_cost_women_changed = 'changed'" v-model.lazy="sab_cost_women" class="text-center"></b-form-input>
                </b-col>
            </b-row>
            <b-row class=" mb-2 variable">
                <b-col v-b-tooltip.hover.top title="Más bajo es mejor" :class="sab_pim_capital_changed" cols="9" class="border-left text-left " >Requisito de capital mínimo pagado (% de INB per cápita)</b-col>
                <b-col class="border-left border-right" >
                    <b-form-input @keydown="onlyDecimal" number trim @input="sab_pim_capital_changed = 'changed'" v-model.lazy="sab_pim_capital" class="text-center"></b-form-input>
                </b-col>
            </b-row>
        </b-container>

    </b-card-text>
    </b-card-body>
</b-collapse>
</b-card>
</div>
</template>

<script>
import { mapState } from 'vuex'
import filters from '../../libs/inputFilters'

export default {
    data() {
        return {
            sab_proc_men_changed: '',
            sab_time_men_changed: '',
            sab_cost_men_changed: '',
            sab_proc_women_changed: '',
            sab_time_women_changed: '',
            sab_cost_women_changed: '',
            sab_pim_capital_changed: ''
        }
    },
    methods: {
        ...filters
    },
    computed: {
        score_changed() {
            if (this.sab_proc_men_changed == 'changed' ||
                this.sab_time_men_changed == 'changed' ||
                this.sab_cost_men_changed == 'changed' ||
                this.sab_proc_women_changed == 'changed' ||
                this.sab_time_women_changed == 'changed' ||
                this.sab_cost_women_changed == 'changed' ||
                this.sab_pim_capital_changed == 'changed')
                return 'changed'
            else return ''
        },
        sab_proc_men: {
            get() {
                return this.$store.state.sab_proc_men
            },
            set(value) {
                this.$store.commit('sab_proc_men', value)
            }
        },
        sab_time_men: {
            get() {
                return this.$store.state.sab_time_men
            },
            set(value) {
                this.$store.commit('sab_time_men', value)
            }
        },
        sab_cost_men: {
            get() {
                return this.$store.state.sab_cost_men
            },
            set(value) {
                this.$store.commit('sab_cost_men', value)
            }},
        sab_proc_women: {
            get() {
                return this.$store.state.sab_proc_women
            },
            set(value) {
                this.$store.commit('sab_proc_women', value)
            }
        },
        sab_time_women: {
            get() {
                return this.$store.state.sab_time_women
            },
            set(value) {
                this.$store.commit('sab_time_women', value)
            }},
        sab_cost_women: {
            get() {
                return this.$store.state.sab_cost_women
            },
            set(value) {
                this.$store.commit('sab_cost_women', value)
            }
        },
        sab_pim_capital: {
            get() {
                return this.$store.state.sab_pim_capital
            },
            set(value) {
                this.$store.commit('sab_pim_capital', value)
            }
        },
        sab_score() {
            if (isNaN(this.$store.getters.sab_score)) return ""
            return this.$store.getters.sab_score.toFixed(1)
        },
    }
}
</script>

<style scoped>
.tarjeta{
color: #5D5D5D;
font-weight: bold;
background-color: white;
text-align: left;
border-color: #81B3E8;
}
.variable{
font-weight: bold;
color: #5D5D5D;
}
@media (min-width: 1900px) and (max-width: 1921px) 
{
.tarjeta
{
    height: 70px;
}
}
.changed {
    background: lightcyan;
}
</style>