import Vue from 'vue'
import Vuex from 'vuex'
import formulas from './formulas'

Vue.use(Vuex)


const setState = key => (state, val) => {
  state[key] = val
}


export default new Vuex.Store({
  state: {
    // variables to calculate score of starting a business
    sab_proc_men: null,
    sab_time_men: null,
    sab_cost_men: null,
    sab_proc_women: null,
    sab_time_women: null,
    sab_cost_women: null,
    sab_pim_capital: null,
    // variables to calculate dealing with construction permits
    dwcp_proc: null,
    dwcp_time: null,
    dwcp_cost: null,
    dwcp_bqci: null,
    // variables to calculate getting electricity
    ge_proc: null,
    ge_time: null,
    ge_cost: null,
    ge_rstt: null,
    // variables to calculate registering property
    rp_proc: null,
    rp_time: null,
    rp_cost: null,
    rp_qlai: null,
    // variables of getting credit
    gc_cii: null,
    gc_lri: null,
    // variables of protecting minority investors
    pmi_di: null,
    pmi_dli: null,
    pmi_ssi: null,
    pmi_sri: null,
    pmi_oci: null,
    pmi_cti: null,
    // variables of paying taxes
    pt_payments: null,
    pt_time: null,
    pt_ttr: null,
    pt_tcvr: null,
    pt_tovr: null,
    pt_t_comply_cita: null,
    pt_t_complete_cita: null,
    pt_pfi: null,
    // variables of trading across borders
    tab_ttebc: null,
    tab_ttedc: null,
    tab_ctebc: null,
    tab_ctedc: null,
    tab_ttibc: null,
    tab_ttidc: null,
    tab_ctibc: null,
    tab_ctidc: null,
    // variables of enforcing contracts
    ec_qjpi: null,
    ec_time: null,
    ec_cost: null,
    // variables of resolving insolvency
    ri_rr: null,
    ri_sifi: null,
  },
  mutations: {
    // mutations for starting a business
    sab_proc_men: setState('sab_proc_men'),
    sab_time_men: setState('sab_time_men'),
    sab_cost_men: setState('sab_cost_men'),
    sab_proc_women: setState('sab_proc_women'),
    sab_time_women: setState('sab_time_women'),
    sab_cost_women: setState('sab_cost_women'),
    sab_pim_capital: setState('sab_pim_capital'),
    // variables to calculate dealing with construction permits
    dwcp_proc: setState('dwcp_proc'),
    dwcp_time: setState('dwcp_time'),
    dwcp_cost: setState('dwcp_cost'),
    dwcp_bqci: setState('dwcp_bqci'),
    // variables to calculate getting electricity
    ge_proc: setState('ge_proc'),
    ge_time: setState('ge_time'),
    ge_cost: setState('ge_cost'),
    ge_rstt: setState('ge_rstt'),
    // variables to calculate registering property
    rp_proc: setState('rp_proc'),
    rp_time: setState('rp_time'),
    rp_cost: setState('rp_cost'),
    rp_qlai: setState('rp_qlai'),
    // variables of getting credit
    gc_cii: setState('gc_cii'),
    gc_lri: setState('gc_lri'),
    // variables of protecting minority investors
    pmi_di: setState('pmi_di'),
    pmi_dli: setState('pmi_dli'),
    pmi_ssi: setState('pmi_ssi'),
    pmi_sri: setState('pmi_sri'),
    pmi_oci: setState('pmi_oci'),
    pmi_cti: setState('pmi_cti'),
    // variables of paying taxes
    pt_payments: setState('pt_payments'),
    pt_time: setState('pt_time'),
    pt_ttr: setState('pt_ttr'),
    pt_tcvr: setState('pt_tcvr'),
    pt_tovr: setState('pt_tovr'),
    pt_t_comply_cita: setState('pt_t_comply_cita'),
    pt_t_complete_cita: setState('pt_t_complete_cita'),
    pt_pfi: setState('pt_pfi'),
    // variables of trading across borders
    tab_ttebc: setState('tab_ttebc'),
    tab_ttedc: setState('tab_ttedc'),
    tab_ctebc: setState('tab_ctebc'),
    tab_ctedc: setState('tab_ctedc'),
    tab_ttibc: setState('tab_ttibc'),
    tab_ttidc: setState('tab_ttidc'),
    tab_ctibc: setState('tab_ctibc'),
    tab_ctidc: setState('tab_ctidc'),
    // variables of enforcing contracts
    ec_qjpi: setState('ec_qjpi'),
    ec_time: setState('ec_time'),
    ec_cost: setState('ec_cost'),
    // variables of resolving insolvency
    ri_rr: setState('ri_rr'),
    ri_sifi: setState('ri_sifi'),
  },
  actions: {
    
  },
  modules: {
  },
  getters: {
    // scores of starting a business
    sab_proc_men_score: state => {
      return formulas.lib(state.sab_proc_men, 1, 18)
    },
    sab_time_men_score: state => {
      return formulas.lib(state.sab_time_men, 0.5, 100)
    },
    sab_cost_men_score: state => {
      return formulas.lib(state.sab_cost_men, 0, 200)
    },
    sab_proc_women_score: state => {
      return formulas.lib(state.sab_proc_women, 1, 18)
    },
    sab_time_women_score: state => {
      return formulas.lib(state.sab_time_women, 0.5, 100)
    },
    sab_cost_women_score: state => {
      return formulas.lib(state.sab_cost_women, 0, 200)
    },
    sab_pim_capital_score: state => {
      return formulas.lib(state.sab_pim_capital, 0, 400)
    },
    sab_score: (state, getters) => {
      let score = (getters.sab_proc_men_score * 0.125) +
        (getters.sab_time_men_score * 0.125) +
        (getters.sab_cost_men_score * 0.125) +
        (getters.sab_proc_women_score * 0.125) +
        (getters.sab_time_women_score * 0.125) +
        (getters.sab_cost_women_score * 0.125) +
        (getters.sab_pim_capital_score * 0.25)
      
      return Math.round(score * 100000) / 100000
    },
    // scores of dealing with construction permits
    dwcp_time_score: state => {
      return formulas.lib(state.dwcp_time, 26, 373)
    },
    dwcp_proc_score: state => {
      return formulas.lib(state.dwcp_proc, 5, 30)
    },
    dwcp_cost_score: state => {
      return formulas.lib(state.dwcp_cost, 0, 20)
    },
    dwcp_bqci_score: state => {
      return formulas.index_score(state.dwcp_bqci, 15)
    },
    dwcp_score: (state, getters) => {
      let score = (
        getters.dwcp_time_score + 
        getters.dwcp_proc_score + 
        getters.dwcp_cost_score + 
        getters.dwcp_bqci_score 
      ) / 4.0

      return Math.round(score * 100000) / 100000
    },
    // scores of getting electricity
    ge_proc_score: state => {
      return formulas.lib(state.ge_proc, 3, 9)
    },
    ge_cost_score: state => {
      return formulas.lib(state.ge_cost, 0, 8100)
    },
    ge_time_score: state => {
      return formulas.lib(state.ge_time, 18, 248)
    },
    ge_rstt_score: state => {
      return formulas.index_score(state.ge_rstt, 8)
    },
    ge_score: (state, getters) => {
      let score = (
        getters.ge_cost_score +
        getters.ge_proc_score +
        getters.ge_time_score +
        getters.ge_rstt_score
      ) / 4.0

      return Math.round(score * 100000) / 100000
    },
    // scores of registering property
    rp_proc_score: state => {
      return formulas.lib(state.rp_proc, 1, 13)
    },
    rp_time_score: state => {
      return formulas.lib(state.rp_time, 1, 210)
    },
    rp_cost_score: state => {
      return formulas.lib(state.rp_cost, 0, 15)
    },
    rp_qlai_score: state => {
      return formulas.index_score(state.rp_qlai, 30)
    },
    rp_score: (state, getters) => {
      let score = (
        getters.rp_proc_score +
        getters.rp_time_score +
        getters.rp_cost_score +
        getters.rp_qlai_score
      ) / 4.0

      return Math.round(score * 100000) / 100000
    },
    // scores of getting credit
    gc_sgc: state => {
      return parseFloat(state.gc_cii) + parseFloat(state.gc_lri)
    },
    gc_score: (state, getters) => {
      let score = formulas.hib(getters.gc_sgc, 20, 0)

      return Math.round(score * 100000) / 100000
    },
    // scores of protecting minority investors
    pmi_di_score: state => {
      return formulas.hib(state.pmi_di, 10, 0)
    },
    pmi_dli_score: state => {
      return formulas.hib(state.pmi_dli, 10, 0)
    },
    pmi_ssi_score: state => {
      return formulas.hib(state.pmi_ssi, 10, 0)
    },
    pmi_sri_score: state => {
      return formulas.hib(state.pmi_sri, 6, 0)
    },
    pmi_oci_score: state => {
      return formulas.hib(state.pmi_oci, 7, 0)
    },
    pmi_cti_score: state => {
      return formulas.hib(state.pmi_cti, 7, 0)
    },
    pmi_score: (state, getters) => {
      let sum = (
        parseFloat(state.pmi_di) +
        parseFloat(state.pmi_dli) +
        parseFloat(state.pmi_ssi) +
        parseFloat(state.pmi_sri) +
        parseFloat(state.pmi_oci) +
        parseFloat(state.pmi_cti)
      )

      let score = formulas.hib(sum, 50, 0)

      return Math.round(score * 100000) / 100000
    },
    // scores of paying taxes
    pt_payments_score: state => {
      return formulas.lib(state.pt_payments, 3, 63)
    },
    pt_time_score: state => {
      return formulas.lib(state.pt_time, 49, 696)
    },
    pt_ttr_score: state => {
      let abs = 57.9
      let best = 26.1
      let worst = 84

      let value = parseFloat(state.pt_ttr)
        if (value == -1) {
          return 0
        } else if (value > worst) {
          return 0
        } else if (value < best) {
          return 1 * 100
        } else {
          return Math.pow(((worst - value) / abs), 0.8) * 100
        }
    },
    pt_score: (state, getters) => {
      let sum = 0
      let score = null

      sum += getters.pt_payments_score
      sum += getters.pt_time_score
      sum += getters.pt_ttr_score

      if (state.pt_pfi) {
        sum += parseFloat(state.pt_pfi)
        score = sum / 4.0
      } else {
        score = sum / 3.0
      }

      return Math.round(score * 100000) / 100000
    },
    // scores of trading across borders
    tab_ttebc_score: state => {
      return formulas.lib(state.tab_ttebc, 1, 160)
    },
    tab_ttedc_score: state => {
      return formulas.lib(state.tab_ttedc, 1, 170)
    },
    tab_ctebc_score: state => {
      return formulas.lib(state.tab_ctebc, 0, 1060)
    },
    tab_ctedc_score: state => {
      return formulas.lib(state.tab_ctedc, 0, 400)
    },
    tab_ttibc_score: state => {
      return formulas.lib(state.tab_ttibc, 1, 280)
    },
    tab_ttidc_score: state => {
      return formulas.lib(state.tab_ttidc, 1, 240)
    },
    tab_ctibc_score: state => {
      return formulas.lib(state.tab_ctibc, 0, 1200)
    },
    tab_ctidc_score: state => {
      return formulas.lib(state.tab_ctidc, 0, 700)
    },
    tab_score: (state, getters) => {
      let score = (
        getters.tab_ctebc_score +
        getters.tab_ctedc_score +
        getters.tab_ttebc_score +
        getters.tab_ttedc_score + 
        getters.tab_ctibc_score +
        getters.tab_ctidc_score +
        getters.tab_ttibc_score +
        getters.tab_ttidc_score
      ) / 8.0

      return Math.round(score * 100000) / 100000
    },
    // scores of enforcing contracts
    ec_cost_score: state => {
      return formulas.lib(state.ec_cost, 0.1, 89)
    },
    ec_qjpi_score: state => {
      return formulas.index_score(state.ec_qjpi, 18)
    },
    ec_time_score: state => {
      return formulas.lib(state.ec_time, 120, 1340)
    },
    ec_score: (state, getters) => {
      let score = (
        getters.ec_cost_score + 
        getters.ec_qjpi_score +
        getters.ec_time_score
      ) / 3.0

      return Math.round(score * 100000) / 100000
    },
    // scores of resolving insolvency
    ri_rr_score: state => {
      return formulas.hib(state.ri_rr, 92.9, 0)
    },
    ri_sifi_score: state => {
      return formulas.hib(state.ri_sifi, 16, 0)
    },
    ri_score: (state, getters) => {
      let score = (
        getters.ri_rr_score +
        getters.ri_sifi_score
      ) / 2.0

      return Math.round(score * 100000) / 100000
    },
    // doing business global score
    db_score: (state, getters) => {
      let score = (
        getters.sab_score +
        getters.ri_score +
        getters.ec_score +
        getters.gc_score +
        getters.pt_score +
        getters.tab_score + 
        getters.rp_score +
        getters.pmi_score +
        getters.dwcp_score +
        getters.ge_score
      ) / 10.0

      return Math.round(score * 100000) / 100000
    }
  }
})
